import { dateToYMD, dateToDanish } from '@/shared/functions/dates'
import { prepareDMRResult } from '@/shared/functions/dmr'
import { isEmpty } from '@/shared/functions/isEmpty'
import { danishLocaleNumber } from '@/shared/functions/numbers'
import { ucwordsImproved } from '@/shared/functions/ucwords'
import { errorList, errorCount } from '@/shared/functions/validationErrors'
import { ComputationEngine2 as ComputationEngine } from '../../computationengine/ComputationEngine2'
import { Broadcaster } from '@/shared/functions/broadcaster'
import { CVR_REGEXP } from '@/shared/regexes'

import kroneTransferConfirmTemplate from '@/shared/templates/modals/kroneTransferConfirm.html'
import kroneErrorTemplate from '@/shared/templates/modals/kroneError.html'
import vehicleCatalogTemplate from '@/shared/templates/modals/vehicleCatalog.html'
import retrofitTemplate from '@/shared/templates/modals/retrofit.html'
import newDMRContractTemplate from '@/shared/templates/modals/newDMRContract.html'
import applicationConditionTemplate from '@/shared/templates/modals/applicationCondition.html'
import leasingkodeTemplate from '@/shared/templates/modals/leasingkode.html'
import genberegningregafgiftTemplate from '@/shared/templates/modals/genberegningregafgift.html'
import prisskiltTemplate from '@/shared/templates/modals/prisskilt.html'
import attachFileTemplate from '@/shared/templates/modals/attachFile.html'
import warningRedoRKITemplate from '@/shared/templates/modals/warningRedoRKI.html'
import mailTemplate from '@/shared/templates/modals/mail.html'
import warningUndoTemplate from '@/shared/templates/modals/warningUndo.html'
import applicationMailExternalTemplate from '@/shared/templates/modals/applicationMailExternal.html'
import asgModal from '@/shared/templates/modals/asgModal.html'
import applicationMailContractTemplate from '@/shared/templates/modals/applicationMailContract.html'
import applicationMailOfferTemplate from '@/shared/templates/modals/applicationMailOffer.html'
import applicationMailMaterialRequestTemplate from '@/shared/templates/modals/applicationMailMaterialRequest.html'
import editInfotextTemplate from '@/shared/templates/modals/editInfotext.html'
import bestyrelsesgodkendelseTemplate from '@/shared/templates/modals/bestyrelsesgodkendelse.html'
import godkendafvisningfejlTemplate from '@/shared/templates/modals/godkendafvisningfejl.html'
import acceptCommentTemplate from '@/shared/templates/modals/acceptComment.html'
import warningSendTemplate from '@/shared/templates/modals/warningSend.html'
import applicationExternalLiabilitiesTemplate from '@/shared/templates/modals/applicationExternalLiabilities.html'
import browserWarningKoerebogTemplate from '@/shared/templates/modals/browserWarningKoerebog.html'
import rejectCommentTemplate from '@/shared/templates/modals/rejectComment.html'

import angular from 'angular'
import { addRoleWatchers } from '@/shared/functions/rolesUtil'
import {
  companyCallback,
  cprLookupMappings,
  cvrLookupMappings,
  cvrLookupMappingsCompanyPage,
  personCallback,
} from '@/shared/functions/cvrcprLookupMappings'
import { Person, ptbModal, SaveReq } from '@/shared/directives/digitalSignatureRecipients/companyModal'
import { Formula } from '../../computationengine/formula'

export const formCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$modal',
  '$templateCache',
  '$timeout',
  '$upload',
  '$interpolate',
  '$dateFormatter',
  '$window',
  '$q',
  'dkkapVarDefs',
  '$interop',
  function (
    $scope,
    $rootScope,
    $http,
    $stateParams,
    $location,
    $modal,
    $templateCache,
    $timeout,
    $upload,
    $interpolate,
    $dateFormatter,
    $window,
    $q,
    dkkapVarDefs,
    $interop
  ) {
    $scope.userLookup = {}
    $scope.users = []
    $scope.companyUsers = [
      {
        id: undefined,
        name: 'Vælg medarbejder',
        company: '',
        deactivated: false,
      },
    ]
    $scope.objectKeys = function (obj) {
      return Object.keys(obj)
    }

    $scope.asgRequesting = false
    $scope.asgdigital = function (callback) {
      $scope.asgRequesting = true

      var kms = $scope.modelVars.asgRequested ? $scope.modelVars.asgKilometerstand : $scope.modelVars.kilometerstand

      $http
        .post('/api/asg/jobByParams', {
          force: true,
          id: $scope.id,
          stelnummer: $scope.modelVars.stelnummer,
          kilometerstand: kms,
          produktlink: $scope.modelVars.produktlink,
        })
        .then(function () {
          $timeout(function () {
            $scope.asgRequesting = false
            $scope.modelVars.asgRequested = true
            if (callback) {
              callback()
            }
          })
        })
      $scope.asgRequesting = false
      $scope.modelVars.asgRequested = true
    }

    $scope.dummyVars = { aaop: undefined, alderKunde: undefined }
    $scope.aaopCondition = function () {
      return true
    }
    $scope.alderKundeCondition = function () {
      return $scope.engine.eval('alderKundeValid')
    }

    $scope.broadcaster = new Broadcaster()
    $scope.showKreditvurderingButton = false

    $scope.cvrConfig = cvrLookupMappings
    $scope.cprConfig = cprLookupMappings
    $scope.acceptButtonStatus = function () {
      if (!$scope.companyGroupLookup) {
        return 'LOADING' // Company groups not loaded yet
      } else {
        var cg = $scope.companyGroupLookup[$scope.engine.eval('companyGroupIdFinal')]
        if (cg && cg.handleExternally) {
          return 'EXTERNAL'
        } else {
          return 'ACCEPT'
        }
      }
    }
    $scope.persons = {}
    $scope.noPenneoCprValidation = {}
    $scope.koerebogClick = function (event) {
      // https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
      var isChromium = window.chrome
      var winNav = window.navigator
      var vendorName = winNav.vendor
      var isOpera = typeof window.opr !== 'undefined'
      var isIEedge = winNav.userAgent.indexOf('Edge') > -1
      var isIOSChrome = winNav.userAgent.match('CriOS')

      if (isIOSChrome) {
        // is Google Chrome on IOS
      } else if (
        isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false
      ) {
        // is Google Chrome
      } else {
        event.stopPropagation()
        event.preventDefault()

        var subScope = $scope.$new(true)
        subScope.aftalenr = $scope.aftalenr
        $modal({ template: browserWarningKoerebogTemplate, show: true, scope: $scope })

        return false
      }
    }

    var getTimeOfDate = function (x) {
      var zero = new Date(1970, 0, 1).getTime()
      if (typeof x === 'string' || x instanceof String) {
        var parts = x.split('-')
        var y = new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10)).getTime()
        return y === zero ? null : y
      } else if (x instanceof Date) {
        var y = x.getTime()
        return y === zero ? null : y
      } else {
        return x
      }
    }

    $scope.collapseStatus = false

    $scope.beforeUnload = function (e) {
      var confirmationMessage = 'Vil du forlade siden uden at gemme?'

      if ($rootScope.currentForm.$dirty && $rootScope.currentStatus === 0) {
        ;(e || window.event).returnValue = confirmationMessage //Gecko + IE
        return confirmationMessage //Gecko + Webkit, Safari, Chrome etc.
      }
    }

    $scope.$on('$destroy', function () {
      $window.removeEventListener('beforeunload', $scope.beforeUnload)
    })

    $window.addEventListener('beforeunload', $scope.beforeUnload)

    // vejl
    $scope.instructions = dkkapVarDefs.instructions
    $scope.leasingRate = dkkapVarDefs.leasingRate
    $scope.leasingPriceBasis = dkkapVarDefs.leasingPriceBasis
    $scope.leasingPriceExtras = dkkapVarDefs.leasingPriceExtras
    $scope.leasingPV = dkkapVarDefs.leasingPV
    $scope.leasingAddons = dkkapVarDefs.leasingAddons
    $scope.leasingDirect = dkkapVarDefs.leasingDirect
    $scope.leasingFixedCosts = dkkapVarDefs.leasingFixedCosts
    $scope.leasingVariableCosts = dkkapVarDefs.leasingVariableCosts
    $scope.leasingVat = dkkapVarDefs.leasingVat

    $scope.shownInstructions = null
    $scope.showInstructions = function () {
      var out = []

      angular.forEach($scope.instructions, function (e) {
        if (!e.condition || $scope.engine.eval(e.condition)) {
          if (!e.conditionCB || e.conditionCB()) {
            // compute interpolation obj
            var fields = {}
            angular.forEach(e.interpolate, function (f) {
              fields[f] = formatValue($scope.variableDefs[f], $scope.engine.eval(f))
            })

            var temp = $interpolate(e.description)
            out.push(temp(fields))
          }
        }
      })

      return out
    }

    $scope.copy = function () {
      var items = [
        'productId',
        'kundetype',
        'cpr',
        'cvr',
        'navn',
        'adresse',
        'postnr',
        'tlf',
        'tlfAlternativ',
        'mail',
        'meddebitor',
        'cpr2',
        'navn2',
        'adresse2',
        'postnr2',
        'tlf2',
        'tlfAlternativ2',
        'mail2',
        'kautionist',
        'kautionistCpr',
        'kautionistCvr',
        'kautionistNavn',
        'kautionistAdresse',
        'kautionistPostnr',
        'kautionistMail',
        'kautionist2',
        'kautionist2Cpr',
        'kautionist2Cvr',
        'kautionist2Navn',
        'kautionist2Adresse',
        'kautionist2Postnr',
        'ejerCpr1',
        'ejerNavn1',
        'ejerCpr2',
        'ejerNavn2',
        'ejerCpr3',
        'ejerNavn3',
        'ejerCpr4',
        'ejerNavn4',
        'netsRegNr',
        'netsKontoNr',
        'netsAfventer',
        'braendstofkortOKMobilPinkode',
        'braendstofkortOKMobil',
        'braendstofkortOKPinkode',
        'braendstofkortOK',
        'braendstofkortUnoXPinkode',
        'braendstofkortUnoX',
        'braendstofkortQ8',
        'braendstofkortShellPinkode',
        'braendstofkortShell',
        'braendstofkortCircleK',
        'boligtype',
        'bruttoloen',
        'nettoloen',
        'raadighedsbeloeb',
        'antalVoksne',
        'antalBoern',
        'haftBilFoer',
        'maerkeModel',
        'varevogn',
        'brugtmoms',
        'registreringsnummer',
        'stelnummer',
        'nyBil',
        'tidligereIndregistreret',
        'indregistreretFoersteGang',
        'ejIndregistreretAargang',
        'campingFastligger',
        'farve',
        'gpsFirmaKode',
        'produktlink',
        'anviserForhandler',
        'anviserKontraktsaet',
        'anviserCpr',
        'anviserCvr',
        'anviserNavn',
        'anviserAdresse',
        'anviserPostnr',
        'anviserTelefon',
        'anviserMail',
        'anviser2',
        'anviser2Kontraktsaet',
        'anviser2Cvr',
        'anviser2Navn',
        'anviser2Adresse',
        'anviser2Postnr',
        'anviser2Telefon',
        'anviser2Mail',
        'beskatningsgrundlagBil',
        'leasinggiverBetalerNummerplader',
        'overkoerselKmPris',
      ]

      var obj = {}
      for (var i in items) {
        var varName = items[i]
        var d = $scope.modelVars[varName]

        if (varName === 'indregistreretFoersteGang' && $scope.vehicleDmr && $scope.vehicleDmr.foersteRegDato) {
          obj[varName] = dateToYMD(new Date($scope.vehicleDmr.foersteRegDato))
        } else {
          obj[varName] = d instanceof Date ? dateToYMD(d) : d
        }
      }

      const company = $scope.userLookup[$scope.modelVars.userId].company
      if ($scope.modelVars.anviserForhandler === 1) {
        obj['anviserForhandler'] = 3
        obj['anviserCvr'] = company.cvr
        obj['anviserNavn'] = company.name
        obj['anviserAdresse'] = company.address
        obj['anviserPostnr'] = company.zipcode
        obj['anviserTelefon'] = company.phone
        obj['anviserMail'] = company.mail
      }
      if ($scope.modelVars.anviser2 === 1) {
        obj['anviser2'] = 2
        obj['anviser2Cvr'] = company.cvr
        obj['anviser2Navn'] = company.name
        obj['anviser2Adresse'] = company.address
        obj['anviser2Postnr'] = company.zipcode
        obj['anviser2Telefon'] = company.phone
        obj['anviser2Mail'] = company.mail
      }
      if ($scope.modelVars.kautionist === 1) {
        obj['kautionist'] = 3
        obj['kautionistCvr'] = company.cvr
        obj['kautionistNavn'] = company.name
        obj['kautionistAdresse'] = company.address
        obj['kautionistPostnr'] = company.zipcode
        obj['kautionistMail'] = company.mail
      }

      obj.forsikringFriskade = true

      // Leasingprodukt
      obj.genleasing = true
      obj.genleasingKontraktnr = $scope.aftalenr

      localStorage.setItem('modelVarsPreload', JSON.stringify(obj))
    }

    $scope.cvrcprGenopslag = function () {
      //erhverv
      for (const cvrField in cvrLookupMappings) {
        if ($scope.engine.eval(cvrField + '.inUse')) {
          const config = cvrLookupMappings[cvrField]
          $http.post('/api/registration/companyAddress/' + $scope.modelVars[cvrField], {}).success(function (obj) {
            companyCallback($scope, obj, config)
          })
        }
      }
      //privat
      for (const cprField in cprLookupMappings) {
        if ($scope.engine.eval(cprField + '.inUse')) {
          const config = cprLookupMappings[cprField]
          $http.post('/api/registration/personAddress/' + $scope.modelVars[cprField]).success(function (obj) {
            personCallback($scope, obj, config)
          })
        }
      }
    }

    $scope.show = function (x) {
      console.log(x)
    }

    $scope.renderTimestamp = function (timestamp) {
      return dateToDanish(new Date(timestamp), true)
    }

    $scope.forceEnabled = {}

    $rootScope.retrofitVars = function () {
      var dateFields = ['forsikringIkraft', 'dmrSynSenestDato']

      function nullIf(v, x) {
        return v === x ? null : v
      }

      var out = []
      for (var v in $scope.forceEnabled) {
        var isDate = dateFields.indexOf(v) > -1

        var isDifferent = isDate
          ? getTimeOfDate($scope.modelVars[v] instanceof Date ? dateToYMD($scope.modelVars[v]) : $scope.modelVars[v]) !=
            getTimeOfDate($scope.currentRow[v])
          : nullIf($scope.modelVars[v], '') != nullIf($scope.currentRow[v], '')

        if (isDifferent) {
          if (v == 'forsikringIkraft') {
            out.push({
              name: v,
              valueBefore: dateToDanish(new Date(getTimeOfDate($scope.currentRow[v]))),
              valueAfter: dateToDanish($scope.modelVars[v]),
            })
          } else if (v == 'insuranceCompanyId') {
            out.push({
              name: v,
              valueBefore: $scope.insuranceCompanyLookup[$scope.currentRow[v]],
              valueAfter: $scope.insuranceCompanyLookup[$scope.modelVars[v]],
            })
          } else if (v == 'dmrSynSenestDato') {
            out.push({
              name: v,
              valueBefore: dateToDanish(new Date(getTimeOfDate($scope.currentRow[v]))),
              valueAfter: dateToDanish($scope.modelVars[v]),
            })
          } else if (v == 'faellespolice') {
            if ($scope.currentRow['valuesJson']) {
              var valuesJson = JSON.parse($scope.currentRow['valuesJson'])
              if (valuesJson.faellespolice) {
                if (valuesJson.forsikringFaellespoliceSelskab != $scope.modelVars['insuranceCompanyId']) {
                  out.push({ name: 'faellespolice', valueBefore: true, valueAfter: false })
                }
              }
            }
          } else if (v == 'asgKilometerstand') {
            //Do nothing
          } else {
            out.push({ name: v, valueBefore: $scope.currentRow[v], valueAfter: $scope.modelVars[v] })
          }
        }
      }
      return out
    }

    $scope.retrofitVarsValid = function (form) {
      for (var v in $scope.forceEnabled) {
        if (form[v] && form[v].$invalid) {
          console.log('invalid retrofit field', v, form[v])
          return false
        }
      }
      return true
    }

    $scope.resetBetalingFoersteGang = function () {
      $scope.modelVars.betalingFoersteGang = $scope.engine.eval($scope.variableDefs.betalingFoersteGang.defaultExpr)
    }
    $scope.getToday = function () {
      var d = new Date()
      return new Date(d.getFullYear(), d.getMonth(), d.getDate())
    }

    $scope.openRetrofitModal = function () {
      var subScope = $scope.$new(true)
      subScope.aftalenr = $scope.aftalenr
      subScope.productId = $scope.modelVars.productId
      subScope.saving = false
      subScope.retrofitVars = $scope.retrofitVars()
      subScope.variableDefs = $scope.variableDefs
      subScope.save = function ($hide, updateNav) {
        subScope.saving = true

        var vs = {}
        angular.forEach(subScope.retrofitVars, function (v) {
          vs[v.name] = v.valueAfter
        })

        for (var v in $scope.forceEnabled) {
          if (subScope.variableDefs[v].vartype == 'model' || vs[v] === undefined) {
            if ($scope.modelVars[v] instanceof Date) {
              vs[v] = $scope.modelVars[v].getTime()
            } else {
              vs[v] = $scope.modelVars[v]
            }
          }
        }

        $http.post('/api/retrofit/retrofitValues/' + $scope.id + '/' + (updateNav ? 1 : 0), vs).success(function (res) {
          $http.post('/api/application/get', param).success(loadForm).error(getFail)
          $hide()
        })
      }
      $modal({ template: retrofitTemplate, show: true, scope: subScope })
    }

    $scope.seasonAdmin = false

    $scope.toggleSeasonAdmin = function () {
      $scope.seasonAdmin = !$scope.seasonAdmin
    }

    $scope.openNewDMRContractModal = function (saeson) {
      var subScope = $scope.$new(true)
      subScope.variableDefs = {
        loebetid: {
          friendly: 'Løbetid (mdr.)',
          datatype: 'count',
          vartype: 'model',
        },
        ydelse: {
          friendly: 'Ydelse',
          datatype: 'amount',
          vartype: 'model',
        },
        rentesats: {
          friendly: 'Rentesats',
          datatype: 'percent',
          vartype: 'model',
        },
        vurderetRegAfgift: {
          friendly: 'Vurderet reg. afgift',
          datatype: 'amount',
          vartype: 'model',
        },
        fakturaprisExMoms: {
          friendly: 'Bilens pris (RV fra NAV)',
          datatype: 'amount',
          vartype: 'model',
        },
      }

      subScope.modelVars = {
        loebetid: $scope.engine.eval('loebetidSaeson' + saeson),
        ydelse: Math.round(0 + $scope.engine.eval('ydelse') + (0 + $scope.engine.eval('sommerbilRegAfgiftSaeson' + saeson))),
        rentesats: $scope.engine.eval('rentesatsEfterReg'),
        vurderetRegAfgift: 0,
        fakturaprisExMoms: null,
      }

      $modal({ template: newDMRContractTemplate, show: true, scope: subScope })
    }

    $scope.materials = []
    $scope.uploading = false

    $scope.websiteLink = function (x) {
      if (x === undefined) return x
      if (x.substr(0, 7) == 'http://') {
        return x
      }
      return 'http://' + x
    }

    $scope.websiteText = function (x) {
      if (x === undefined) return x
      if (x.substr(0, 7) == 'http://') {
        return x.substr(7)
      }
      return x
    }

    $scope.fieldDescriptions = {}

    $scope.autostuffWatches = {}

    $scope.orderByValue = function (value) {
      return value.name
    }
    $scope.cityVars = {}

    $scope.findLeasingDelete = function () {
      if (!$scope.modelVars.externFindLeasingId) {
        console.error('FindLeasing ID ej fundet')
      } else {
        $http.post('/api/findleasing/delete/' + $scope.id).then(function (res) {
          $scope.modelVars.externFindLeasingId = null
        })
      }
    }

    $scope.findLeasingTransferring = false
    $scope.findLeasingTransfer = function () {
      if (!$scope.modelVars.externAutoitId) {
        console.error('Bilinfo ID ej fundet')
      } else {
        $scope.findLeasingTransferring = true
        var payload = {
          vehicle_id: $scope.modelVars.externAutoitId,
          offer_id: $scope.modelVars.externFindLeasingId,
          list_type: 0,
          funding: $scope.modelVars.leasingtype ? 0 : 1,
          ownership: 1,
          price: Math.round($scope.engine.eval('totalprisExMoms')),
          price_tax: $scope.modelVars.brugtmoms,
          tax_price: $scope.modelVars.vurderetRegAfgift,
          first_registration: dateToYMD(
            $scope.modelVars.nyBil ? new Date() : $scope.modelVars.indregistreretFoersteGang,
          ),
          remaining_value: Math.round($scope.engine.eval('restvaerdi')),
          first_pay: Math.round($scope.engine.eval('foersteYdelseExMoms')),
          deposit: null,
          establishing_price: Math.round(
            $scope.engine.eval('fasteOmkostninger') -
              $scope.engine.eval('dokumentgebyrFinal') - // == ekstraMaanedRegAfgiftTotal + loebetidAar*dokumentgebyr + loebetid*opkraevningsgebyr
              $scope.engine.eval('ekspeditionsgebyrAfterWrite'), // == loebetidAar*ekspeditionsgebyr
          ),
          cost_per_year: Math.round($scope.engine.eval('dokumentgebyr') + $scope.engine.eval('ekspeditionsgebyr')),
          cost_per_month: Math.round($scope.engine.eval('opkraevningsgebyr')),
          delivery: $scope.modelVars.leveringsomkostningerExMoms,
          plates_cost: Math.round($scope.engine.eval('nummerpladerFinal')),
          handling: Math.round($scope.engine.eval('stiftelsesprovisionEfterReg')),
          interest_rate: ($scope.engine.eval('rentesatsEfterReg') * 100).toFixed(2),
          taxation: $scope.modelVars.beskatningsgrundlagBil,
          date_to_date_taxation: $scope.engine.eval('ekstraMaanedRegAfgiftInactive'),
        }

        $http.post('/api/findleasing/save', { offer: payload, applicationId: $scope.id }).then(function (res) {
          $scope.modelVars.externFindLeasingId = res.data.id
          $scope.findLeasingTransferring = false
        })
      }
    }

    $scope.findLeasingSynced = false
    $scope.findLeasingSync = function () {
      console.log('sync start')
      if ($scope.modelVars.externFindLeasingId) {
        $http.post('/api/findleasing/syncActiveIds').then(function (res) {
          if (!res.data[$scope.modelVars.externFindLeasingId]) {
            $scope.modelVars.externFindLeasingId = null
          }
          $scope.findLeasingSynced = true
          console.log('sync performed')
        })
      } else {
        $scope.findLeasingSynced = true
        console.log('sync not performed')
      }
    }

    var vehicleCatalogModal = $modal({ scope: $scope, template: vehicleCatalogTemplate, show: false })

    $scope.vehicleModalMode = undefined
    $scope.knownVehicles = undefined
    $scope.filteredKnownVehicles = undefined
    $scope.search = { text: '' }

    $scope.openVehicleCatalog = function (e, mode) {
      e.preventDefault()
      $scope.vehicleModalMode = mode

      var url =
        mode === 'autoit'
          ? '/api/autoit/getAll'
          : mode === 'findleasing'
          ? '/api/fl/getResult'
          : '/api/existingVehicles/getTop/' + $scope.modelVars.productId + '/1000'

      $http.get(url).success(function (res0) {
        var res = mode === 'autoit' ? res0.items : res0
        $scope.knownVehicles = res
        $scope.filteredKnownVehicles = res
      })

      console.log(mode)
      $scope.debug = function (x) {
        console.log(x)
      }

      vehicleCatalogModal.$promise.then(vehicleCatalogModal.show)
    }

    $scope.selectKnownVehicle = function (vehicle) {
      $timeout(function () {
        unregisterWatches() // avoid
        vehicleCatalogModal.hide()
        if ($scope.vehicleModalMode === 'autoit') {
          if (!!vehicle.registrationDate && vehicle.registrationDate.length > 4) {
            $scope.modelVars.nyBil = false
            $scope.modelVars.indregistreretFoersteGang = new Date(vehicle.registrationDate)
          }
          $scope.modelVars.externAutoitId = vehicle.manualId
          $scope.modelVars.farve = vehicle.color
          $scope.modelVars.stelnummer = vehicle.vin
          $scope.modelVars.registreringsnummer = vehicle.registrationNo
          $scope.modelVars.maerkeModel = (vehicle.make + ' ' + vehicle.model + ' ' + vehicle.variant).substring(0, 60)
          $scope.modelVars.kilometerstand = vehicle.mileage
        } else if ($scope.vehicleModalMode === 'findleasing') {
          var item = vehicle.item

          if (item.car.first_registration) {
            $scope.modelVars.nyBil = false
            $scope.modelVars.indregistreretFoersteGang = new Date(item.car.first_registration)
          }

          if (item.funding.id == 1) {
            // financial leasing
            $scope.modelVars.isSplitleasing = false
            $scope.modelVars.isSeasonLeasing = false
            $scope.modelVars.regTax = 0
            $scope.modelVars.leasingtype = false
            $scope.modelVars.kundetype = true
            $scope.setUseRestvaerdiCalc('restvaerdi')
            $scope.engine.clearExpressionCache() // Interest rate depends on productId + age of car

            $scope.modelVars.fakturaprisExMoms = item.price
            $scope.modelVars.restvaerdiExMomsWrite = item.remaining_value
            $scope.modelVars.depositum = item.deposit || 0

            $scope.modelVars.useDisableEkstraMaanedRegAfgift = true
            $scope.modelVars.disableEkstraMaanedRegAfgift = true

            if (item.car.tax_interest_rate) {
              $scope.modelVars.useRenteRegAfgiftPctWrite = true
              $scope.modelVars.renteRegAfgiftPctWrite = item.car.tax_interest_rate / 100
            } else {
              $scope.modelVars.useRenteRegAfgiftPctWrite = false
            }

            $scope.modelVars.vurderetRegAfgift = item.car.tax_price

            $scope.modelVars.nummerplader = item.car.plates_cost || 0
            $scope.modelVars.nummerpladerLocked = true
          } else {
            // operating leasing
            $scope.modelVars.isSplitleasing = false
            $scope.modelVars.isSeasonLeasing = false
            $scope.modelVars.regTax = 1
            $scope.modelVars.leasingtype = true
            $scope.modelVars.kundetype = true
            $scope.setUseRestvaerdiCalc('ydelse')
            $scope.engine.clearExpressionCache() // Interest rate depends on productId + age of car

            $scope.modelVars.nummerplader = 0
            $scope.modelVars.nummerpladerLocked = true

            $scope.modelVars.fakturaprisExMoms = item.price

            $scope.modelVars.ydelseWrite = item.price_monthly
          }
          $scope.modelVars.rentesatsRegSuper = item.interest_rate / 100 - $scope.engine.eval('rentesatsFoerRegSuper')

          $scope.modelVars.leveringsomkostningerExMoms = item.delivery || 0

          $scope.modelVars.loebetidWrite = item.period
          $scope.modelVars.loebetidChanged = true

          $scope.modelVars.foersteYdelseExMomsWrite = item.first_pay
          $scope.modelVars.foersteYdelseExMomsLocked = true

          $scope.modelVars.useDeklarationForsikringWrite = true
          $scope.modelVars.deklarationForsikringWrite = item.handling || 0

          $scope.modelVars.useDokumentgebyrWrite = true
          $scope.modelVars.dokumentgebyrWrite = item.establishing_price || 0

          $scope.modelVars.stelnummer = item.car.vin_number || ''
          $scope.modelVars.maerkeModel = item.full_title || ''
          $scope.modelVars.kilometerstand = item.car.mileage || 0

          $scope.modelVars.friKm = item.free_mileage
          $scope.modelVars.maxKoerselPrAar = item.car.kilometers || 0

          $scope.engine.clearExpressionCache()
        } else {
          $scope.modelVars.maerkeModel = vehicle.maerkeModel
          $scope.modelVars.registreringsnummer = vehicle.registreringsnummer
          $scope.modelVars.stelnummer = vehicle.stelnummer
          $scope.modelVars.nyBil = false
          $scope.modelVars.tidligereIndregistreret = true
          $scope.modelVars.indregistreretFoersteGang = vehicle.indregistreretFoersteGang
          $scope.modelVars.farve = vehicle.farve
          $scope.modelVars.vurderetRegAfgift = vehicle.vurderetRegAfgift
          $scope.modelVars.kilometerstand = vehicle.kilometerstand
          $scope.modelVars.fakturaprisExMoms = vehicle.bookValue
        }
        registerWatches()
      })
    }

    $scope.filterKnownVehicles = function () {
      const searchText = $scope.search.text.toLowerCase()

      if ($scope.vehicleModalMode !== 'autoit' && $scope.vehicleModalMode !== 'findleasing') {
        $scope.filteredKnownVehicles = $scope.knownVehicles.filter(function (vehicle) {
          return (
            vehicle.maerkeModel.toLowerCase().indexOf(searchText) !== -1 ||
            vehicle.stelnummer.toLowerCase().indexOf(searchText) !== -1 ||
            vehicle.farve.toLowerCase().indexOf(searchText) !== -1 ||
            vehicle.registreringsnummer.toLowerCase().indexOf(searchText) !== -1
          )
        })
      } else if ($scope.vehicleModalMode === 'autoit') {
        $scope.filteredKnownVehicles = $scope.knownVehicles.filter(function (vehicle) {
          return (
            vehicle.make.toLowerCase().indexOf(searchText) !== -1 ||
            vehicle.model.toLowerCase().indexOf(searchText) !== -1 ||
            vehicle.variant.toLowerCase().indexOf(searchText) !== -1 ||
            (vehicle.color || '').toLowerCase().indexOf(searchText) !== -1 ||
            vehicle.manualId.toString().toLowerCase().indexOf(searchText) !== -1
          )
        })
      } else if ($scope.vehicleModalMode === 'findleasing') {
        $scope.filteredKnownVehicles = $scope.knownVehicles.filter(function (vehicle) {
          return (
            vehicle.item.full_title.toLowerCase().indexOf(searchText) !== -1 ||
            vehicle.item.funding.text.toLowerCase().indexOf(searchText) !== -1
          )
        })
      }
    }

    var initializeDownloadButton = function (downloadButtonState) {
      var out = {}
      for (var i in $scope.downloadButtonStates) {
        var n = $scope.downloadButtonStates[i].name
        if (downloadButtonState[n] !== undefined) {
          out[n] = downloadButtonState[n]
        } else {
          out[n] = $scope.downloadButtonStates[i].default
        }
      }
      return out
    }

    var initializeRegisterButton = function (registerButtonState) {
      var out = {}
      for (var i in $scope.registerButtonStates) {
        var n = $scope.registerButtonStates[i].name
        if (registerButtonState[n] !== undefined) {
          out[n] = registerButtonState[n]
        } else {
          out[n] = $scope.registerButtonStates[i].default()
        }
      }
      return out
    }
    $scope.translateHPStrToInt = function (hpStr) {
      if (hpStr === '=' || hpStr === '') return 0
      const regex = /\([0-9]+ HK\)/
      const match = hpStr.match(regex)[0]
      return parseInt(match.substring(1, match.length - 4))
    }

    $scope.motorregisterResult = {}
    $scope.motorregister = function (type) {
      var query = ''
      if (type == undefined) {
        query = $scope.dmrsoegning
      }
      if (type == 'registreringsnummer') {
        query = $scope.modelVars.registreringsnummer
      }
      if (type == 'stelnummer') {
        query = $scope.modelVars.stelnummer
      }
      $scope.motorregisterResult = {
        register: 'motorregister',
        type: 'wait',
      }
      $http.post('/api/DMRLookup/' + query).success(function (res) {
        var parseDmrDate = function (str) {
          if (str != '-' && !!str) {
            var temp = str.split('-')
            if (temp.length == 3) {
              return new Date(temp[2], temp[1] - 1, temp[0])
            }
          }
          return null
        }

        $scope.motorregisterSections = prepareDMRResult(res)
        $scope.motorregisterResult = res

        if (res.type == 'success' && $scope.status == 0) {
          if (
            !!res.result['Køretøj']['Registreringsforhold']['Første registreringsdato'] &&
            res.result['Køretøj']['Registreringsforhold']['Første registreringsdato'] != '-'
          ) {
            var temp = res.result['Køretøj']['Registreringsforhold']['Første registreringsdato'].split('-')
            if (temp.length > 0) {
              modelVars.nyBil = false
              modelVars.indregistreretFoersteGang = new Date(temp[2], temp[1] - 1, temp[0])
            }
          }

          var co2udslip = res.result['Tekniske oplysninger']['Målt forbrug']['CO2udslip']
          if (co2udslip === '-') {
            modelVars.co2Udslip = 0
          } else {
            modelVars.co2Udslip = parseFloat(co2udslip.replace('g/km', '').trim().replace(',', '.'))
          }

          var maalemetode = res.result['Tekniske oplysninger']['Målt forbrug']['Målenorm']
          if (maalemetode === '-') {
            modelVars.maalemetode = ''
          } else {
            modelVars.maalemetode = maalemetode
          }

          modelVars.dmrArt = res.result['Køretøj']['Køretøj']['Art']
          modelVars.dmrSynSenestDato = parseDmrDate(res.result['Syn']['Synsindkaldelse(r)']['Indkaldt til syn senest'])
          modelVars.dmrSynFrekvens = res.result['Syn']['Synsindkaldelse(r)']['Frekvens for periodisk syn']
          modelVars.horsePower = $scope.translateHPStrToInt(
            res.result['Tekniske oplysninger']['Motor osv.']['Største effekt'],
          )

          function parseKmlNumber(x) {
            var num = parseFloat(x.split(' ')[0].replace(',', '.'))
            if (!isNaN(num) && isFinite(num)) {
              return num
            }
            return 0
          }

          modelVars.dmrSynNaesteDato = parseDmrDate(
            res.result['Syn']['Synsindkaldelse(r)']['Beregnet dato for næste indkaldelse til periodisk syn'],
          )

          modelVars.stelnummer = res.result['Køretøj']['Køretøj']['Stelnummer']
          modelVars.anslaaetKmPrLiterBraendstof = parseKmlNumber(
            res.result['Tekniske oplysninger']['Forbrug (afgiftsgrundlag)']['Brændstofforbrug'],
          )
          modelVars.varevogn = res.result['Køretøj']['Køretøj']['Art'] != 'Personbil'

          var drivkraftToBraendstofstype = {
            Benzin: 1,
            Diesel: 2,
            El: 3,
            Hybrid: 4,
            'Hybrid plugin': 5,
          }

          var braendstofstypeTemp =
            drivkraftToBraendstofstype[res.result['Tekniske oplysninger']['Motor osv.']['Drivkraft']]

          if (braendstofstypeTemp) {
            modelVars.braendstofstype = braendstofstypeTemp
          }

          function dmrAmountToNumber(dmrAmount) {
            if (dmrAmount) {
              var num = parseFloat(
                dmrAmount
                  .slice(0, dmrAmount.length - 4)
                  .replace('.', '')
                  .replace(',', '.'),
              )
              if (!isNaN(num) && isFinite(num)) {
                return num
              }
            }
            return 0
          }
          var groenEjerAfgiftTemp
          if (
            !!(
              res.result['Afgifter']['Periodiske afgifter'] &&
              !!res.result['Afgifter']['Periodiske afgifter']['_tables']['Detaljer for periodisk afgift']
            )
          ) {
            groenEjerAfgiftTemp = dmrAmountToNumber(
              (res.result['Afgifter']['Periodiske afgifter']['_tables']['Detaljer for periodisk afgift'][0] || {})[
                'Beløb'
              ],
            )
          }

          if (!!groenEjerAfgiftTemp) {
            modelVars.anslaaetGroenEjerafgiftPrAar = groenEjerAfgiftTemp
          }

          if (modelVars.maerkeModel == '') {
            modelVars.maerkeModel = ucwordsImproved(res.result['Køretøj']['Køretøj']['Mærke, Model, Variant'])
              .split(' ')
              .map(function (x) {
                return x.replace(/,$/, '')
              })
              .filter(function (x) {
                return x.toLowerCase() != 'ukendt' && x.toLowerCase() != 'uoplyst'
              })
              .join(' ')
              .substring(0, 60)
          }
          if (
            res.result['Køretøj']['Identifikation']['Farve'] != 'Ukendt' &&
            res.result['Køretøj']['Identifikation']['Farve'] != '-'
          ) {
            modelVars.farve = res.result['Køretøj']['Identifikation']['Farve']
          }
        }
      })
    }

    $scope.bilbogen = function () {
      var query = $scope.modelVars.stelnummer
      $scope.motorregisterResult = {
        register: 'bilbogen',
        type: 'wait',
      }
      $http.post('/api/bilbogenLookup/' + query).success(function (res) {
        $scope.motorregisterSections = res.sections
        $scope.motorregisterResult = res
      })
    }

    $scope.downloadLinks = [
      {
        pdflink: 'kontraktvars',
        active: function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
        },
        friendly: 'Kontrakt (variable)',
      },
      {
        pdflink: 'contract',
        active: function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
        },
        friendly: 'Kontrakt (udkast)',
      },
      {
        href: function() {
          return `/api/${$interop.module}/krone/preview/` + $scope.id + ''
        },
        target: '_blank',
        friendly: 'Krone Eksport',
        active: function() {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin && $scope.id != undefined
        },
      }
    ]

    $scope.savingTooLow = function () {
      return (
        isSplitleasing() &&
        modelVars.anslaaetAndelPrivatkoersel > 0 &&
        $scope.engine.eval('anslaaetBeskatningBesparelse') < $scope.engine.eval('splitTotalKraevetBesparelse')
      )
    }

    $scope.partPrivateTooHigh = function () {
      return isSplitleasing() && modelVars.anslaaetAndelPrivatkoersel > serverVars.splitAndelPrivatAdvarelse
    }

    var contractIsApproved = function () {
      return $scope.status == 2 && $scope.currentRow.approvalStatus == true
    }
    var contractHasCondition = function () {
      return $scope.currentRow.betingelseKunde != '' || $scope.currentRow.betingelseSaelger != ''
    }
    var isFinansiering = function () {
      return false
    }
    var isLeasing = function () {
      return true
    }
    var isSplitleasing = function () {
      return $scope.modelVars.isSplitleasing
    }
    var isSommerbil = function () {
      return $scope.modelVars.isSeasonLeasing
    }
    var isKoebstaederne = function () {
      return $scope.engine.eval('faellespolice', true) //$scope.insuranceCompanyLookupReverse['Købstædernes Forsikring'];
    }

    $scope.insuranceUseable = function (str) {
      return $scope.insuranceCompanyLookupReverse[str] !== undefined
    }

    $scope.insuranceSelect = function (str) {
      $scope.modelVars.insuranceCompanyId = $scope.insuranceCompanyLookupReverse[str]
    }

    $scope.extractRegistrationDate = function (str) {
      var parts = str.substring(str.length - 10).split('-')
      return new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10))
    }

    $scope.getContractDropdown = function (a, $hide) {
      if (a.approvalStatus === true) {
        return [
          {
            text: 'Hent kontrakt MED kundekopi',
            click: $hide,
            href: '/api/application/contract/' + a.id + '/1',
            target: '_blank',
          },
          {
            text: 'Hent kontrakt UDEN kundekopi',
            click: $hide,
            href: '/api/application/contract/' + a.id + '/0',
            target: '_blank',
          },
        ]
      }
    }

    $scope.condition = function (a) {
      var subscope = $rootScope.$new()
      subscope.a = a
      subscope.getContractDropdown = $scope.getContractDropdown
      subscope.closeModal = function () {
        subscope.$hide()
      }
      $modal({ template: applicationConditionTemplate, show: true, scope: subscope })
    }

    $scope.contractIsApproved = contractIsApproved

    $scope.registerButtonStates = [
      {
        name: 'rki',
        active: function () {
          return true
        },
        default: function () {
          return true
        },
        friendly: 'RKI',
      },
      {
        name: 'dowjones',
        active: function () {
          return true
        },
        default: function () {
          return true
        },
        friendly: 'Dow Jones',
      },
      {
        name: 'tinglysning',
        active: function () {
          return true
        },
        default: function () {
          return true
        },
        friendly: 'Tinglysning',
      },
      {
        name: 'cvr',
        active: function () {
          return $scope.modelVars.kundetype
        },
        default: function () {
          return true
        },
        friendly: 'CVR',
      },
      {
        name: 'ejerstruktur',
        active: function () {
          return $scope.modelVars.kundetype
        },
        default: function () {
          return true
        },
        friendly: 'Ejerstruktur',
      },
      {
        name: 'motorregister',
        active: function () {
          return true
        },
        default: function () {
          return true
        },
        friendly: 'Motorregister',
      },
      {
        name: 'bilbogen',
        active: function () {
          return true
        },
        default: function () {
          return true
        },
        friendly: 'Bilbogen',
      },
      {
        name: 'husstandsoplysningerudfyldt',
        active: function () {
          return !$scope.modelVars.kundetype
        },
        default: function () {
          return false
        },
        friendly: 'Husstandsoplysninger',
      },
    ]

    $scope.downloadButtonStates = [
      {
        name: 'inklOmkostninger',
        active: function () {
          return false
        },
        default: false,
        friendly: 'Vis omkostninger',
      },
      {
        name: 'inklRegistreringsafgift',
        active: function () {
          return $scope.modelVars.regTax !== 1
        },
        default: true,
        friendly: 'Vis vurderet reg.afgift',
      },
      {
        name: 'inklForhandler',
        active: function () {
          return !$scope.engine.eval('usesCompanyLogo')
        },
        default: true,
        friendly: 'Vis forhandlerinformation',
      },
      {
        name: 'inklLeveringsdato',
        active: function () {
          return $scope.modelVars.regTax !== 1
        },
        default: true,
        friendly: 'Vis leveringsdato',
      },
      {
        name: 'inklBeskatningsgrundlag',
        active: function () {
          return !$scope.modelVars.isSplitleasing && $scope.modelVars.regTax !== 2
        },
        default: true,
        friendly: 'Vis beskatningsgrundlag',
      },
      {
        name: 'inklTotalprisExMoms',
        active: function () {
          return $scope.modelVars.regTax !== 2
        },
        default: false,
        friendly: 'Vis totalpris ex. moms',
      },
      {
        name: 'inklOverkoerselKmPris',
        active: function () {
          return $scope.modelVars.regTax !== 2
        },
        default: true,
        friendly: 'Vis pris pr. overkørt km',
      },
      {
        name: 'inklFaellespolice',
        active: function () {
          return $scope.modelVars.regTax === 0
        },
        default: true,
        friendly: 'Vis fællespolice-tilbud',
      },
    ]
    $scope.contractDownloadLinks = []
    $scope.downloadButtonState = initializeDownloadButton({})
    $scope.registerButtonState = initializeRegisterButton({})

    $scope.$watch('userInfo', function () {
      if ($scope.userInfo !== undefined && $scope.userInfo.downloadButtonState !== undefined) {
        $scope.contractDownloadLinks = [
          {
            href: '/api/application/contract/' + $stateParams.id + '/1',
            target: '_blank',
            active: function () {
              return contractIsApproved() && !contractHasCondition() && !$scope.modelVars.isSeasonLeasing
            },
            friendly: 'Kontraktdokumentsæt MED kundekopi',
          },
          {
            href: '/api/application/contract/' + $stateParams.id + '/0',
            target: '_blank',
            active: function () {
              return contractIsApproved() && !contractHasCondition() && !$scope.modelVars.isSeasonLeasing
            },
            friendly: 'Kontraktdokumentsæt UDEN kundekopi',
          },
          {
            click: 'condition(currentRow)',
            active: function () {
              return contractIsApproved() && contractHasCondition() && !$scope.modelVars.isSeasonLeasing
            },
            friendly: 'Kontraktdokumentsæt (Betinget)',
          },
        ]

        if ($scope.userInfo.isSuperAdmin) {
          $scope.contractDownloadLinks = $scope.contractDownloadLinks.concat([
            {
              pdflink: 'supplerendeoplysninger',
              friendly: 'Supplerende oplysninger',
              active: function () {
                return contractIsApproved()
              },
            },
            {
              pdflink: 'pbs',
              friendly: 'Nets-aftale',
              active: function () {
                return contractIsApproved() && isFinansiering()
              },
            },
            {
              pdflink: 'laaneaftale',
              friendly: 'Låneaftale',
              active: function () {
                return contractIsApproved() && isFinansiering() && $scope.modelVars.gaeldsbrev
              },
            },
            {
              pdflink: 'kontrakt',
              friendly: 'Finanseringskontrakt',
              active: function () {
                return contractIsApproved() && isFinansiering() && !$scope.modelVars.gaeldsbrev
              },
            },
            {
              pdflink: 'ejerpantebrev',
              friendly: 'Ejerpantebrev',
              active: function () {
                return (
                  contractIsApproved() &&
                  isFinansiering() &&
                  $scope.modelVars.gaeldsbrev &&
                  !$scope.modelVars.gaeldsbrevUdenPant
                )
              },
            },
            {
              pdflink: 'ejendomsforbehold',
              friendly: 'Ejendomsforbehold',
              active: function () {
                return contractIsApproved() && isFinansiering() && !$scope.modelVars.gaeldsbrev
              },
            },
            {
              pdflink: 'sef',
              friendly: 'SEF-skema',
              active: function () {
                return contractIsApproved() && isFinansiering()
              },
            },
            {
              pdflink: 'skatLeasingkontrakt',
              friendly: 'Leasingkontrakt',
              active: function () {
                return contractIsApproved() && isLeasing() && !$scope.modelVars.isSeasonLeasing
              },
              params: {
                customerNumber: '',
              },
            },
            ,
            {
              pdflink: 'skatLeasingkontrakt',
              friendly: 'Leasingkontrakt (Bruger 2)',
              active: function () {
                return contractIsApproved() && isSplitleasing() && !$scope.modelVars.isSeasonLeasing
              },
              params: {
                customerNumber: '2',
              },
            },
            {
              pdflink: 'skatAllongeSplit',
              friendly: 'Allonge',
              active: function () {
                return contractIsApproved() && isSplitleasing()
              },
              params: {
                customerNumber: '',
              },
            },
            {
              pdflink: 'skatAllongeSplit',
              friendly: 'Allonge (Bruger 2)',
              active: function () {
                return contractIsApproved() && isSplitleasing()
              },
              params: {
                customerNumber: '2',
              },
            },
            {
              pdflink: 'skatAllongeAnviser',
              friendly: 'Anviser køber',
              active: function () {
                return isLeasing()
              },
              params: {
                customerNumber: '',
              },
            },

            {
              pdflink: 'declaration',
              friendly: 'Panthaverdeklaration',
              active: function () {
                return isLeasing()
              },
              params: {
                customerNumber: '',
              },
            },

            {
              pdflink: 'anviserprivatforhandler',
              friendly: 'Anviser køber - sekundær',
              active: function () {
                return isLeasing() && $scope.modelVars.anviser2 > 0
              },
              params: {
                customerNumber: '',
              },
            },
            {
              pdflink: 'allongeindfrielse',
              friendly: 'Allonge, indfrielse',
              active: function () {
                return $scope.userInfo && $scope.userInfo.isSuperAdmin && !isLeasing()
              },
              params: {
                customerNumber: '',
              },
            },
            {
              pdflink: 'skatAllongeLeverancegodkendelse',
              friendly: 'Leverancegodkendelse',
              active: function () {
                return contractIsApproved() && isLeasing()
              },
              params: {
                customerNumber: '',
              },
            },
            {
              pdflink: 'skatAllongeLeverancegodkendelse',
              friendly: 'Leverancegodkendelse (Bruger 2)',
              active: function () {
                return contractIsApproved() && isSplitleasing()
              },
              params: {
                customerNumber: '2',
              },
            },
            {
              pdflink: 'trooglove',
              friendly: 'Tro- og love erklæring (Bruger 2)',
              active: function () {
                return (
                  ((contractIsApproved() && isKoebstaederne()) || $scope.userInfo.isSuperAdmin) &&
                  $scope.modelVars.regTax !== 1
                )
              },
              params: {
                customerNumber: '2',
              },
            },
            {
              pdflink: 'trooglove',
              friendly: 'Tro- og love erklæring',
              active: function () {
                return (
                  ((contractIsApproved() && isKoebstaederne()) || $scope.userInfo.isSuperAdmin) &&
                  isLeasing() &&
                  $scope.modelVars.regTax !== 1
                )
              },
              params: {
                customerNumber: '',
              },
            },
            {
              pdflink: 'skatLeasingkontrakt',
              friendly: 'SKAT Leasingkontrakt',
              active: function () {
                return isLeasing() && $scope.userInfo.isSuperAdmin
              },
              params: {
                customerNumber: '',
                showFields: true,
                version: 'SKAT',
              },
            },
            {
              pdflink: 'skatAllongeAnviser',
              friendly: 'SKAT A1 - Anviser køber',
              active: function () {
                return isLeasing() && $scope.userInfo.isSuperAdmin
              },
              params: {
                customerNumber: '',
                showFields: true,
              },
            },
            {
              pdflink: 'skatAllongeAnviserPrivatForhandler',
              friendly: 'SKAT A1B - Anviser - Sekundær',
              active: function () {
                return isLeasing() && $scope.userInfo.isSuperAdmin
              },
              params: {
                customerNumber: '',
                showFields: true,
              },
            },
            {
              pdflink: 'skatAllongeSplit',
              friendly: 'SKAT A2 - Splitleasing',
              active: function () {
                return isLeasing() && $scope.userInfo.isSuperAdmin
              },
              params: {
                customerNumber: '',
                showFields: true,
              },
            },
            {
              pdflink: 'skatAllongeSplit',
              friendly: 'SKAT A2 - Splitleasing (Bruger 2)',
              active: function () {
                return isLeasing() && $scope.userInfo.isSuperAdmin
              },
              params: {
                customerNumber: '2',
                showFields: true,
              },
            },
            {
              pdflink: 'skatAllongeKaution',
              friendly: 'SKAT A4 - Selvskyldnerkaution',
              active: function () {
                return isLeasing() && $scope.userInfo.isSuperAdmin
              },
              params: {
                customerNumber: '',
                showFields: true,
              },
            },
            {
              pdflink: 'skatAllongeLeverancegodkendelse',
              friendly: 'SKAT A5 - Leverancegodkendelse',
              active: function () {
                return isLeasing() && $scope.userInfo.isSuperAdmin
              },
              params: {
                customerNumber: '',
                showFields: true,
              },
            },
            {
              pdflink: 'trooglove',
              friendly: 'SKAT - Tro- og loveerklæring',
              active: function () {
                return isLeasing() && $scope.userInfo.isSuperAdmin
              },
              params: {
                customerNumber: '',
                showFields: true,
              },
            },
          ])
        }

        $scope.downloadButtonState = initializeDownloadButton(JSON.parse($scope.userInfo.downloadButtonState))
      }
    })
    $scope.testevent = function (e) {
      return false
    }

    $scope.toggleButtonState = function (n) {
      $scope.downloadButtonState[n] = !$scope.downloadButtonState[n]
      $http.post('/api/setDownloadButtonState', $scope.downloadButtonState)

      return false
    }

    $scope.toggleRKI = function (rkiRegistration, setting, id) {
      rkiRegistration.manuallyApproved = setting
      if (id.length == 10) {
        $http.post('/api/application/saveRKIApprovedPerson', {
          cpr: id,
          setting: setting,
          id: $scope.id,
        })
      } else {
        $http.post('/api/application/saveRKIApprovedCompany', {
          cpr: id,
          setting: setting,
          id: $scope.id,
        })
      }
    }

    $scope.$on('creditRating.parentToggleDJ', function (event, argsObj) {
      for (var item in $scope.dowJonesResult) {
        if (item.cpr == argsObj.cpr) {
          item.manuallyApproved = argsObj.setting
        }
      }
    })

    $scope.toggleRegisterButtonState = function (n) {
      $scope.registerButtonState[n] = !$scope.registerButtonState[n]
      return false
    }

    $scope.registerButtonQueryString = function () {
      if ($scope.registerButtonState !== undefined) {
        var out = []
        for (var i in $scope.registerButtonStates) {
          if ($scope.registerButtonStates[i].active()) {
            var item = $scope.registerButtonStates[i].name
            out.push(item + '=' + ($scope.registerButtonState[item] ? 1 : 0))
          }
        }
        return out.join('&')
      }
      return ''
    }

    var getDownloadLinks = function (arr) {
      var out1 = []

      for (var i in arr) {
        if (arr[i].active()) {
          out1.push({
            text: '<i class="fa fa-print"></i> ' + arr[i].friendly,
            params: JSON.stringify(arr[i].params),
            pdflink: arr[i].pdflink,
            href: typeof arr[i].href == 'function' ? arr[i].href() : arr[i].href,
            click: arr[i].click,
            target: arr[i].target,
            downloadButtonState: $scope.downloadButtonState,
          })
        }
      }
      return out1
    }

    $scope.downloadButtonDropdown = function () {
      var out2 = []
      for (var i in $scope.downloadButtonStates) {
        if ($scope.downloadButtonStates[i].active()) {
          var c = $scope.downloadButtonState[$scope.downloadButtonStates[i].name] ? 'fa-check-square-o' : 'fa-square-o'
          out2.push({
            text: '<i class="fa ' + c + '"></i> ' + $scope.downloadButtonStates[i].friendly,
            click: "toggleButtonState('" + $scope.downloadButtonStates[i].name + "')",
          })
        }
      }
      return out2
    }

    $scope.registerButtonDropdown = function () {
      var out2 = []
      for (var i in $scope.registerButtonStates) {
        if ($scope.registerButtonStates[i].active()) {
          var c = $scope.registerButtonState[$scope.registerButtonStates[i].name] ? 'fa-check-square-o' : 'fa-square-o'
          out2.push({
            text: '<i class="fa ' + c + '"></i> ' + $scope.registerButtonStates[i].friendly,
            click: "toggleRegisterButtonState('" + $scope.registerButtonStates[i].name + "')",
          })
        }
      }
      return out2
    }

    $scope.setReviewButtonDropdown = function () {
      if ($scope.currentRow === undefined) {
        return []
      }

      var reviewEmployeeId = $scope.currentRow.reviewEmployee
      return $scope.users
        .filter(function (user) {
          return $scope.userLookup[user.id].company.id === 1 && !$scope.userLookup[user.id].user.deactivated
        })
        .map(function (user) {
          var c = reviewEmployeeId === user.id ? 'fa-check-circle-o' : 'fa-circle-o'
          var click =
            reviewEmployeeId === user.id ? 'setReview(false,' + user.id + ')' : 'setReview(true,' + user.id + ')'
          return {
            text: '<i class="fa ' + c + '"></i> ' + user.name,
            click: click,
          }
        })
    }

    $scope.setOtherButtonDropdown = function () {
      var res = []
      if (param.id === undefined) {
        return res
      }
      if ($scope.userInfo === undefined) {
        return res
      }

      if ($scope.currentRow !== undefined) {
        if ($scope.status >= 1 && $scope.userInfo.isSuperAdmin && $scope.currentRow.archive === false) {
          res.push({
            text: '<i class="fa fa-archive"></i> &nbsp; Arkivér',
            click: $scope.archive,
          })
        }
        if ($scope.status >= 1 && $scope.userInfo.isSuperAdmin && $scope.currentRow.archive === true) {
          res.push({
            text: '<i class="fa fa-undo"</i>&nbsp; Gendan fra arkiv',
            click: $scope.unarchive,
          })
        }
      }
      if ($scope.userInfo.isSuperAdmin && $scope.modelVars.adminmode === true) {
        res.push({
          text: '<i class="fa fa-undo"></i> &nbsp; Konvertér til forhandlerkladde',
          click: $scope.convertToDraft,
        })
      }
      if ($scope.userInfo.isSuperAdmin && $scope.modelVars.adminmode === false) {
        res.push({
          text: '<i class="fa fa-undo"></i> &nbsp; Konvertér til superkladde',
          click: $scope.convertToSuperDraft,
        })
      }
      return res
    }

    $scope.dmrContractDropdown = function () {
      var out2 = []
      for (var i = 1; i <= $scope.engine.eval('sommerbilAntalSaesoner'); i++) {
        out2.push({
          text:
            'Sæson ' +
            i +
            ' (' +
            $dateFormatter.formatDate($scope.engine.eval('startdatoSaeson' + i), 'dd.MM.yyyy') +
            '-' +
            $dateFormatter.formatDate($scope.engine.eval('slutdatoSaeson' + i), 'dd.MM.yyyy') +
            ')',
          click: 'openNewDMRContractModal(' + i + ')',
        })
      }
      return out2
    }

    $scope.moreDownloadButtonDropdown = function () {
      var out1 = getDownloadLinks($scope.downloadLinks)

      var out3 = getDownloadLinks($scope.contractDownloadLinks)
      if (out1.length > 0 && out3.length > 0) {
        out1.push({
          divider: true,
        })
      }
      return out1.concat(out3)
    }
    $scope.downloadButtonTitle = function () {
      return 'Udskriv leasingforslag'
    }

    $scope.leasingkodeParams = {
      nypris: 0,
      handelspris: 0,
      eftermonteretUdstyr: 0,
      fastPeriode: false,
      kronekontrakt: '',
      forsikring: '',
    }
    var leasingkodeScope = $rootScope.$new()

    var leasingkodeModal = $modal({ scope: leasingkodeScope, template: leasingkodeTemplate, show: false })

    $scope.leasingkode = function (isNyAfgift) {
      leasingkodeScope.id = $scope.id
      leasingkodeScope.formIsLoaded = true
      leasingkodeScope.modelVars = {}
      leasingkodeScope.isNyAfgift = isNyAfgift
      leasingkodeScope.forceParams = { force: false }
      leasingkodeScope.errorCount = errorCount

      leasingkodeScope.options = $scope.options
      leasingkodeScope.engine = new ComputationEngine(
        leasingkodeScope.modelVars,
        serverVars,
        function () {
          return $scope.userInfo.id
        },
        function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
        },
        function () {
          return $scope.engine.eval('forsikringFaellespoliceSelskab')
        },
        dkkapVarDefs.variableDefs,
      )

      // Vi tillader at der kan være fejl i angulars formvalidering, når en beregning er låst
      // Derfor skal vi bruge initialModelVars hvor angular ikke har sat felter til undefined
      // Helt konkret havde vi problemet med restværdi på sæsonleasingsager
      angular.extend(leasingkodeScope.modelVars, $scope.formIsDisabled ? $scope.initialModelVars : $scope.modelVars)

      leasingkodeScope.transferToCerno = function ($hide) {}

      leasingkodeScope.variableDefs = $scope.variableDefs

      leasingkodeScope.$watchCollection('modelVars', function (newVar, oldVar) {
        leasingkodeScope.engine.clearExpressionCache()
      })

      leasingkodeModal.$promise.then(leasingkodeModal.show)
    }

    var genberegningRegAfgiftScope = $rootScope.$new()
    genberegningRegAfgiftScope.renderDate = dateToDanish
    var genberegningRegAfgiftModal = $modal({
      scope: genberegningRegAfgiftScope,
      template: genberegningregafgiftTemplate,
      show: false,
    })

    $scope.genberegningregafgift = function () {
      genberegningRegAfgiftScope.formIsLoaded = true
      genberegningRegAfgiftScope.modelVars = {}

      genberegningRegAfgiftScope.errorCount = errorCount

      genberegningRegAfgiftScope.options = $scope.options
      genberegningRegAfgiftScope.engine = new ComputationEngine(
        genberegningRegAfgiftScope.modelVars,
        serverVars,
        function () {
          return $scope.userInfo.id
        },
        function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
        },
        function () {
          return $scope.engine.eval('forsikringFaellespoliceSelskab')
        },
        dkkapVarDefs.variableDefs,
      )
      angular.extend(genberegningRegAfgiftScope.modelVars, $scope.modelVars)

      genberegningRegAfgiftScope.variableDefs = $scope.variableDefs

      genberegningRegAfgiftScope.$watchCollection('modelVars', function (newVar, oldVar) {
        genberegningRegAfgiftScope.engine.clearExpressionCache()
      })

      genberegningRegAfgiftModal.$promise.then(genberegningRegAfgiftModal.show)
    }

    $scope.prisskiltParams = {}

    var prisskiltScope = $rootScope.$new()

    var prisskiltModal = $modal({ scope: prisskiltScope, template: prisskiltTemplate, show: false })

    $scope.prisskilt = function () {
      prisskiltScope.formIsLoaded = true
      prisskiltScope.modelVars = {}

      prisskiltScope.errorCount = errorCount

      prisskiltScope.options = $scope.options
      prisskiltScope.engine = new ComputationEngine(
        prisskiltScope.modelVars,
        serverVars,
        function () {
          return $scope.userInfo.id
        },
        function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
        },
        function () {
          return $scope.engine.eval('forsikringFaellespoliceSelskab')
        },
        dkkapVarDefs.variableDefs,
      )
      angular.extend(prisskiltScope.modelVars, $scope.modelVars)

      prisskiltScope.variableDefs = $scope.variableDefs

      prisskiltScope.$watchCollection('modelVars', function (newVar, oldVar) {
        prisskiltScope.engine.clearExpressionCache()
      })

      prisskiltModal.$promise.then(prisskiltModal.show)
    }

    var attachFileModal = $modal({ scope: $scope, template: attachFileTemplate, show: false })

    $scope.openAttachFileModal = function (e) {
      e.preventDefault()
      attachFileModal.$promise.then(attachFileModal.show)
    }

    $scope.bundleUrl = function () {
      if ($scope.id === undefined) {
        return '/api/applicationUpload/getBundleTemp/' + $scope.uploaddir
      } else {
        return '/api/applicationUpload/getBundle/' + $scope.id
      }
    }

    $scope.materialExtCount = function (matchExt) {
      var c = 0
      angular.forEach($scope.materials, function (m) {
        var ext = m.filename.split('.').slice('-1').join().toLowerCase()
        if (ext == matchExt) {
          c++
        }
      })
      return c
    }

    $scope.reloadMaterials = function () {
      if ($scope.id === undefined) {
        if ($scope.uploaddir !== undefined) {
          $http
            .post('/api/applicationUpload/getMaterialListTemp/' + $scope.uploaddir)
            .success(function (res) {
              $scope.materials = res.files
            })
            .error(function (res) {
              // TODO: Could not load materials
            })
        }
      } else {
        $http
          .post('/api/applicationUpload/getMaterialList/' + $scope.id)
          .success(function (res) {
            $scope.materials = res.files
          })
          .error(function (res) {
            // TODO: Could not load materials
          })
      }
    }

    $scope.uploadMaterial = function ($close) {
      if ($scope.selectedMaterials) {
        if ($scope.id !== undefined) {
          var uploadUrl = '/api/applicationUpload/uploadMaterials/' + $scope.id
        } else {
          if ($scope.uploaddir === undefined) {
            var uploadUrl = '/api/applicationUpload/uploadMaterialsTemp'
          } else {
            var uploadUrl = '/api/applicationUpload/uploadMaterialsTemp/' + $scope.uploaddir
          }
        }
        $scope.uploading = true

        $upload
          .upload({
            url: uploadUrl,
            file: $scope.selectedMaterials,
          })
          .error(function (data, status, headers, config) {
            $scope.successMsg = ''
            $scope.errorMsg = 'Materialer kunne ikke uploades.'
          })
          .success(function (data, status, headers, config) {
            if ($scope.id === undefined) {
              $scope.uploaddir = data
            }
            $scope.uploading = false
            $scope.errorMsg = ''
            $scope.successMsg = ''
            attachFileModal.hide()
            $scope.reloadMaterials()
          })
      }
    }

    $scope.deleteMaterial = function (m) {
      var index = $scope.materials.indexOf(m)
      if (index > -1) {
        $scope.materials.splice(index, 1)
      }

      if ($scope.id === undefined) {
        if ($scope.uploaddir !== undefined) {
          $http
            .post('/api/applicationUpload/deleteMaterialTemp/' + $scope.uploaddir + '?filename=' + m.filename)
            .success(function (res) {
              console.log('Deleted material ' + m.filename)
            })
            .error(function (res) {
              console.log('Could not delete material ' + m.filename + ': ' + res)
            })
        }
      } else {
        $http
          .post('/api/applicationUpload/deleteMaterial/' + $scope.id + '?filename=' + m.filename)
          .success(function (res) {
            console.log('Deleted material ' + m.filename)
          })
          .error(function (res) {
            console.log('Could not delete material ' + m.filename + ': ' + res)
          })
      }
    }

    $scope.onMaterialSelect = function ($files) {
      $scope.selectedMaterials = $files
    }

    $scope.setWarning = function (warning, warningField) {
      $scope[warningField] = warning
    }

    var serverVars = {}
    var modelVars = {}

    var originalEngine = new ComputationEngine(
      modelVars,
      serverVars,
      function () {
        return $scope.userInfo.id
      },
      function () {
        return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
      },
      function () {
        return $scope.engine.eval('forsikringFaellespoliceSelskab')
      },
      dkkapVarDefs.variableDefs,
    ) // hent senere serverVars ind...
    originalEngine.setErrorReporting({typeErrors: false})

    var engine = originalEngine

    var param = {}
    if ($stateParams.id !== undefined) {
      param.id = parseInt($stateParams.id, 10)
    }

    var DummyEngine = function (variableDefs, values) {
      for (var i in values) {
        if (variableDefs[i] !== undefined && variableDefs[i].datatype === 'date') {
          if (values[i] !== null) {
            values[i] = new Date(getTimeOfDate(values[i]))
          }
        }
      }

      this.eval = function (identifier, fallback) {
        if (values[identifier] === undefined) {
          return fallback
        }
        return values[identifier]
      }
      this.getValues = function () {
        return values
      }
      this.resetComputation = function () {}
      this.clearExpressionCache = function () {}
      this.getVariableDefs = function () {
        return variableDefs
      }
    }

    $scope.formIsLoaded = false
    $scope.formIsDisabled = false
    $scope.justInitialized = {}

    $scope.errorCount = errorCount
    $scope.errorList = errorList

    $scope.status = 0
    $scope.currentRow = {}

    var cachedRes = {} // "Fortryd"

    var getFail = function () {
      $scope.setGlobalAlert('Beregningen kunne ikke åbnes!')
      $location.url('/')
    }
    $scope.cernoSuccess = false
    $scope.cernoWorking = false
    $scope.transferToCerno = function () {}

    $scope.transferToPenneo = function () {
      $scope.penneoWorking = true
      return $http
        .post('/penneo/transfer/' + param.id, {
          ...{ persons: $scope.persons, noPenneoCprValidation: $scope.noPenneoCprValidation },
        })
        .then(() => {
          $scope.penneoSuccess = true
        })
    }


    $scope.transferDeliveryApprovalToPenneo = function () {
      $scope.digitalSignature(() => {
        $scope.penneoDeliveryApprovalWorking = true
        return $http
            .post('/penneo/transfer/deliveryApproval/' + param.id, {
              ...{ persons: $scope.persons, noPenneoCprValidation: $scope.noPenneoCprValidation },
            })
            .then(() => {
              $scope.penneoDeliveryApprovalSuccess = true
              $scope.penneoDeliveryApprovalTransferred = true
            })
      })
    }

    $scope.customerInfoLinkSuccess = false
    $scope.customerInfoLinkWorking = false
    $scope.customerInfoLink1 = ''
    $scope.customerInfoLink2 = ''
    $scope.createCustomerInfoLinks = function () {
      $scope.customerInfoLinkWorking = true
      $http.post('/api/application/createKeyForUserLink/' + param.id).success(function (response) {
        $scope.customerInfoLink1 = response[0].url
        if (response.length === 2) {
          $scope.customerInfoLink2 = response[1].url
        }
        $scope.customerInfoLinkStatusBool = 0
        $scope.customerInfoLinkSuccess = true
      })
    }

    $scope.aftalenr = null
    $scope.aftalenr2 = null

    $scope.showMail = function (id) {
      var modalScope = $scope.$new()
      modalScope.mailHistoryId = id
      modalScope.mailContent = undefined
      $http.get('/api/mailhistory/' + id + '/json').success(function (json) {
        modalScope.mailContent = json
      })
      var modal = $modal({
        scope: modalScope,
        template: mailTemplate,
        show: false,
      })
      modal.$promise.then(modal.show)
    }
    $scope.getSignerStatus = function () {
      $http.get('/penneo/signerStatus/' + $scope.id).success(function (topJson) {
        $scope.penneoErrorMsg = {}
        $scope.signerStatus = {}
        for (let [tpe, json] of Object.entries(topJson)) {
          if (!!json.error) {
            $scope.penneoErrorMsg[tpe] = json.error
          } else {
            $scope.signerStatus[tpe] = json.success
          }
        }
      })
    }

    $scope.penneoSigningLinkTypeNameDanish = function(typeName) {
      switch (typeName) {
        case 'Leasingkontrakt': return 'Leasingkontrakt'
        case 'Leverancegodkendelse': return 'Leverancegodkendelse'
        default: return typeName
      }
    }

    $scope.statusToText = function (id) {
      switch (id) {
        case 0:
          return 'Ny'
        case 1:
          return 'Afventer'
        case 2:
          return 'Afvist'
        case 3:
          return 'Slettet'
        case 4:
          return 'Underskrevet'
        case 5:
          return 'Færdiggjort'
        case 6:
          return 'Fejlet'
        default:
          return 'Fejl'
      }
    }
    $scope.statusToColor = function (id) {
      switch (id) {
        case 0:
          return 'text-warning'
        case 1:
          return 'text-warning'
        case 2:
          return 'text-danger'
        case 3:
          return 'text-danger'
        case 4:
          return 'text-success'
        case 5:
          return 'text-success'
        case 6:
          return 'text-danger'
        default:
          return 'text-danger'
      }
    }
    $scope.statusToIcon = function (id) {
      switch (id) {
        case 0:
          return 'fa-question-circle'
        case 1:
          return 'fa-question-circle'
        case 2:
          return 'fa-exclamation-circle '
        case 3:
          return 'fa-exclamation-circle '
        case 4:
          return 'fa-check-circle'
        case 5:
          return 'fa-check-circle'
        case 6:
          return 'fa-exclamation-circle '
        default:
          return 'fa-exclamation-circle '
      }
    }

    var loadForm = function (res) {
      $scope.forceEnabled = {}
      $scope.saving = false
      unregisterWatches()

      $scope.mailhistory = res.mailhistory
      if (res.modelVars !== undefined) {
        $scope.aftalenr = res.modelVars.aftalenr
        $scope.aftalenr2 = res.modelVars.aftalenr2
      }

      $scope.vehicleDmr = res.vehicleDmr
      engine = originalEngine
      $scope.fieldDescriptions = res.infotexts
      $scope.engine = engine
      engine.setErrorReporting({typeErrors: true})
      $scope.formIsLoaded = false
      $scope.formIsDisabled = false
      $scope.status = 0
      $scope.currentRow = res.modelVars
      $scope.penneoSigningLinks = res.penneoSigningLinks
      $scope.penneoDeliveryApprovalTransferred = res.penneoDeliveryApprovalTransferred

      $scope.signature = res.signature
      $scope.rkiRegistrations = {}
      $scope.showRkiRegistrations = false

      cachedRes = res
      var defs = engine.getVariableDefs()
      for (var field in res.serverVars) {
        serverVars[field] = res.serverVars[field]
      }
      $scope.products = res.products
      $scope.insuranceCompanies = res.insuranceCompanies
      $scope.insuranceCompanyLookup = {}
      $scope.insuranceCompanyLookupReverse = {}
      for (var i in $scope.insuranceCompanies) {
        $scope.insuranceCompanyLookup[$scope.insuranceCompanies[i].id] = $scope.insuranceCompanies[i].name
        $scope.insuranceCompanyLookupReverse[$scope.insuranceCompanies[i].name] = $scope.insuranceCompanies[i].id

        var temp = $scope.insuranceCompanies[i]
        for (var j in temp.synonyms) {
          $scope.insuranceCompanyLookupReverse[temp.synonyms[j]] = $scope.insuranceCompanies[i].id
        }
      }

      $scope.options.gpsFirmaKode = []
      for (var i in res.gpsProviders) {
        $scope.options.gpsFirmaKode.push({ value: res.gpsProviders[i].name, option: res.gpsProviders[i].caption })
      }

      $scope.options.gpsMedKoerebog = [
        { value: false, option: 'GPS-tracker uden kørebog' },
        { value: true, option: 'GPS-tracker med kørebog' },
      ]

      $scope.companyGroupCount = 0
      $scope.companyGroups = res.companyGroups
      $scope.companyGroupLookup = {}
      $scope.companyGroupLookupReverse = {}
      for (var i in $scope.companyGroups) {
        $scope.companyGroupCount++
        $scope.companyGroupLookup[$scope.companyGroups[i].id] = $scope.companyGroups[i]
        $scope.companyGroupLookupReverse[$scope.companyGroups[i].name] = $scope.companyGroups[i].id
      }

      if (res.values === undefined) {
        engine.resetComputation($scope.justInitialized) // Kun indlæs default-værdier når den er ved at blive redigeret!
      }

      if (param.id !== undefined) {
        $scope.id = param.id
        $scope.reloadMaterials()

        var values = res.values !== undefined ? res.values : res.modelVars
        for (var field in values) {
          if (defs[field] !== undefined) {
            if (defs[field].datatype == 'date' || defs[field].datatype == 'year') {
              if (values[field] === null) {
                $scope.modelVars[field] = null
              } else {
                $scope.modelVars[field] = new Date(getTimeOfDate(values[field]))
              }
            } else {
              $scope.modelVars[field] = values[field] // håndtér datoer etc
            }
            if (field == 'insuranceCompanyId' && $scope.modelVars[field] == 0) {
              $scope.modelVars[field] = null
            }
            if (field == 'productId' && $scope.products[$scope.modelVars[field]] == undefined) {
              $scope.modelVars[field] = null
            }
            // SPECIEL HÅNDTERING AF "GAMMELDAGS" ANVISER FORHANDLER:
            if (field == 'anviserForhandler' && $scope.modelVars[field] === false) {
              $scope.modelVars[field] = 0
              $scope.modelVars.anviserKontraktsaet = true
            }
            if (field == 'anviserForhandler' && $scope.modelVars[field] === true) {
              $scope.modelVars[field] = 1
              $scope.modelVars.anviserKontraktsaet = false
            }

            $scope.justInitialized[field] = [] //true;
          }
        }

        // ---
        // Af hensyn til Anmodning om leasingkode-formularen, gemmer vi også initialModelVars
        // Vi tillader at der kan være fejl i angulars formvalidering, når en beregning er låst
        // Derfor skal vi bruge initialModelVars hvor angular ikke har sat felter til undefined

        var preparedValues = {}
        for (var field in values) {
          if (defs[field] !== undefined) {
            if (defs[field].datatype == 'date' || defs[field].datatype == 'year') {
              if (values[field] === null) {
                preparedValues[field] = null
              } else {
                preparedValues[field] = new Date(getTimeOfDate(values[field]))
              }
            } else {
              preparedValues[field] = values[field] // håndtér datoer etc
            }
            if (field == 'insuranceCompanyId' && preparedValues[field] == 0) {
              preparedValues[field] = null
            }
            if (field == 'productId' && $scope.products[preparedValues[field]] == undefined) {
              preparedValues[field] = null
            }
            // SPECIEL HÅNDTERING AF "GAMMELDAGS" ANVISER FORHANDLER:
            if (field == 'anviserForhandler' && preparedValues[field] === false) {
              preparedValues[field] = 0
              preparedValues.anviserKontraktsaet = true
            }
            if (field == 'anviserForhandler' && preparedValues[field] === true) {
              preparedValues[field] = 1
              preparedValues.anviserKontraktsaet = false
            }
          }
        }
        $scope.initialModelVars = preparedValues
        $scope.getSignerStatus()

        if (res.values !== undefined) {
          if ($scope.userInfo.isSuperAdmin) {
            $scope.forceEnabled = {
              navn: true,
              adresse: true,
              postnr: true,
              tlf: true,
              tlfAlternativ: true,
              mail: true,

              navn2: true,
              adresse2: true,
              postnr2: true,
              tlf2: true,
              tlfAlternativ2: true,
              mail2: true,

              registreringsnummer: true,
              stelnummer: true,
              asgKilometerstand: true,
              co2Udslip: true,
              kilometerstand: true,
              insuranceCompanyId: true,
              maerkeModel: true,
              farve: true,
              produktlink: true,

              faellespolice: true,
              forsikringIkraft: true,

              ejerCpr1: true,
              ejerNavn1: true,
              ejerCpr2: true,
              ejerNavn2: true,
              ejerCpr3: true,
              ejerNavn3: true,
              ejerCpr4: true,
              ejerNavn4: true,

              kommentar: true,
              statustekst: true,

              netsRegNr: true,
              netsKontoNr: true,
              netsAfventer: true,

              beskatningsgrundlagBil: true,

              leasinggiverBetalerNummerplader: true,

              boligtype: true,
              bruttoloen: true,
              nettoloen: true,
              raadighedsbeloeb: true,
              antalVoksne: true,
              antalBoern: true,
              haftBilFoer: true,
              betingelseKunde: true,
              betingelseSaelger: true,

              dmrSynSenestDato: true,

              kautionist: true,
              kautionistCpr: true,
              kautionistCvr: true,
              kautionistNavn: true,
              kautionistAdresse: true,
              kautionistPostnr: true,
              kautionistMail: true,
              kautionist2: true,
              kautionist2Cpr: true,
              kautionist2Cvr: true,
              kautionist2Navn: true,
              kautionist2Adresse: true,
              kautionist2Postnr: true,
              kautionist2Mail: true,

              anviserForhandler: true,
              anviserKontraktsaet: true,
              anviserCpr: true,
              anviserCvr: true,
              anviserNavn: true,
              anviserAdresse: true,
              anviserPostnr: true,
              anviserTelefon: true,
              anviserMail: true,

              anviser2: true,
              anviser2Kontraktsaet: true,
              anviser2Cvr: true,
              anviser2Navn: true,
              anviser2Adresse: true,
              anviser2Postnr: true,
              anviser2Telefon: true,
              anviser2Mail: true,
            }
          }

          $scope.engine = new DummyEngine(engine.getVariableDefs(), res.values)
          $scope.variableDefs = $scope.engine.getVariableDefs()
          $scope.formIsDisabled = true
        }
        $scope.status = $scope.currentRow.status
        if ($scope.status > 0) {
          $http.post('/api/ptb/getCvrs/' + $scope.id).success(function (res) {
            $scope.cvrs = res
          })
        }

        engine.clearExpressionCache()
      } else {
        var json = localStorage.getItem('modelVarsPreload')
        var values = JSON.parse(json)
        if (values) {
          for (var field in values) {
            if (defs[field] !== undefined) {
              if (defs[field].datatype == 'date' || defs[field].datatype == 'year') {
                if (values[field] === null) {
                  $scope.modelVars[field] = null
                } else {
                  $scope.modelVars[field] = new Date(getTimeOfDate(values[field]))
                }
              } else {
                $scope.modelVars[field] = values[field] // håndtér datoer etc
              }
              if (field == 'insuranceCompanyId' && $scope.modelVars[field] == 0) {
                $scope.modelVars[field] = null
              }
              if (field == 'productId' && $scope.products[$scope.modelVars[field]] == undefined) {
                $scope.modelVars[field] = null
              }
              // SPECIEL HÅNDTERING AF "GAMMELDAGS" ANVISER FORHANDLER:
              if (field == 'anviserForhandler' && $scope.modelVars[field] === false) {
                $scope.modelVars[field] = 0
                $scope.modelVars.anviserKontraktsaet = true
              }
              if (field == 'anviserForhandler' && $scope.modelVars[field] === true) {
                $scope.modelVars[field] = 1
                $scope.modelVars.anviserKontraktsaet = false
              }

              $scope.justInitialized[field] = [] //true;
            }
          }
          $scope.engine.clearExpressionCache()
          $scope.modelVars.loebetidWrite = $scope.engine.eval($scope.variableDefs.loebetidWrite.defaultExpr)
          $scope.modelVars.betalingFoersteGang = $scope.engine.eval($scope.variableDefs.betalingFoersteGang.defaultExpr)
          $scope.modelVars.totalprisMoms = $scope.engine.eval($scope.variableDefs.totalprisMoms.defaultExpr)
          // Now included in modelVarsPreload! $scope.modelVars.overkoerselKmPris = engine.eval($scope.variableDefs.overkoerselKmPris.defaultExpr);
        }

        localStorage.removeItem('modelVarsPreload')
        /* */
      }
      $http.post('/api/company/getAll').success(function (companies) {
        // når denne er hentet laves
        var companyLookup = {}
        for (var i in companies) {
          companyLookup['' + companies[i].id] = companies[i]
        }

        $http.post('/api/user/getAllByCompanyId').success(function (res) {
          var cid = $scope.userInfo.companyId

          for (var companyId in res) {
            if ($scope.userInfo.isSuperAdmin || companyId == cid) {
              for (var i in res[companyId]) {
                $scope.userLookup['' + res[companyId][i].id] = {
                  user: res[companyId][i],
                  company: companyLookup[companyId],
                }

                if (!res[companyId][i].deactivated || res[companyId][i].id === $scope.modelVars.userId) {
                  $scope.users.push({
                    id: res[companyId][i].id,
                    name: res[companyId][i].name,
                    deactivated: res[companyId][i].deactivated,
                    company: companyLookup[companyId].name,
                  })
                }
              }
            }
          }

          for (var i in res[cid]) {
            $scope.companyUsers.push({
              id: res[cid][i].id,
              name: res[cid][i].name,
              deactivated: res[cid][i].deactivated,
              company: companyLookup[cid].name,
            })
          }
          $scope.companyUsers = $scope.companyUsers.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)
          $scope.users = $scope.users.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)
        })
      })

      $scope.financingPartnerCompanyLookup = {}
      $scope.financingPartnerCompanies = []

      $http.post('/api/financingPartner/getAll').success(function (fps) {
        var fpLookup = {}
        for (var i in fps) {
          fpLookup[fps[i].financingPartnerCode] = fps[i]
        }

        $http.post('/api/financingPartner/getAllCompanies').success(function (res) {
          for (var companyId in res) {
            for (var i in res[companyId]) {
              $scope.financingPartnerCompanyLookup[res[companyId][i].cvr] = {
                user: res[companyId][i],
                company: fpLookup[companyId],
              }

              $scope.financingPartnerCompanies.push({
                id: res[companyId][i].cvr,
                name: res[companyId][i].name,
                company: fpLookup[companyId].name,
              })
            }
          }
        })
      })

      if (
        $scope.userInfo.isSuperAdmin ||
        $scope.engine.eval('godkendtKautionist') === 1 ||
        $scope.modelVars.godkendtKautionistMaskinrum
      ) {
        $scope.options.kautionist.push({ value: 1, option: 'Ja, forhandler er kautionist' })
      }

      if (typeof $scope.modelVars.boligtype == 'boolean' || $scope.modelVars.boligtype instanceof Boolean) {
        $scope.modelVars.boligtype = $scope.modelVars.boligtype ? 1 : 0
      }

      $scope.modelVars.asgKilometerstand = $scope.engine.eval($scope.variableDefs.asgKilometerstand.defaultExpr)

      $scope.formIsLoaded = true
      //setTimeout(function(){
      $scope.form.$setPristine()
      $rootScope.currentForm = $scope.form
      $rootScope.currentStatus = $scope.modelVars.status
      registerWatches()
      //},100);

      $scope.findLeasingSync()

      $scope.registerButtonState = initializeRegisterButton({})
    }

    $scope.checkDirty = function () {
      angular.forEach($scope.form, function (input, key) {
        if (input.$dirty) {
          console.log(input, key)
        }
      })
    }

    $scope.undo = function () {
      var undoWarning = $modal({
        scope: $scope,
        template: warningUndoTemplate,
        show: false,
      })
      undoWarning.$promise.then(undoWarning.show)
    }

    $scope.doUndo = function () {
      loadForm(cachedRes)
    }

    $scope.dmrRegistrationDateWarning = function () {
      if ($scope.vehicleDmr && $scope.vehicleDmr.foersteRegDato && !$scope.modelVars.nyBil) {
        var beregnerDato = $scope.modelVars.indregistreretFoersteGang
        var dmrDato = new Date($scope.vehicleDmr.foersteRegDato)

        if (dateToYMD(beregnerDato) != dateToYMD(dmrDato)) {
          return (
            'Der står forkert 1. indregistreringsdato i denne beregning. Den korrekte dato fra DMR er ' +
            dateToDanish(dmrDato) +
            '.'
          )
        }
      }
      return null
    }

    $scope.modelVars = modelVars
    $scope.products = {}
    $scope.insuranceCompanies = {}
    $scope.variableDefs = engine.getVariableDefs()
    $scope.engine = engine
    $scope.showmode = 0 // FAV eller ej

    $scope.mainTabs = [
      {
        title: 'Finansieringsoplysninger',
        content: '',
      },
      {
        title: 'Kundeoplysninger',
        content: '',
      },
      {
        title: 'Køretøj',
        content: '',
      },
    ]

    $scope.mainTabsReverse = {
      Finansieringsoplysninger: 0,
      Kundeoplysninger: 1,
      Køretøj: 2,
    }

    $scope.mainTabs.currentMainTab = 'Finansieringsoplysninger'

    $scope.hasTab = function (tabName) {
      return $scope.mainTabsReverse[tabName] !== undefined
    }

    $scope.addTab = function (tabObject) {
      if (!$scope.hasTab(tabObject.title)) {
        $scope.mainTabs.push(tabObject)
        $scope.mainTabsReverse[tabObject.title] = $scope.mainTabs.length - 1
      }
    }

    $scope.removeTab = function (tabName) {
      if ($scope.hasTab(tabName)) {
        var offset = $scope.mainTabsReverse[tabName]
        $scope.mainTabs.splice(offset, 1)
        delete $scope.mainTabsReverse[tabName]

        for (var i = offset; i < $scope.mainTabs.length; i++) {
          $scope.mainTabsReverse[$scope.mainTabs[i]] = i
        }
      }
    }

    $scope.currentTabTitle = function () {
      return $scope.mainTabs[$scope.mainTabs.currentMainTab].title
    }

    $scope.statusDescription = ['Gem som kladde', 'Færdig ansøgning']

    $scope.statusDropdown = [
      {
        text: 'Gem som kladde',
        click: 'modelVars.status = 0',
      },
      {
        text: 'Færdig ansøgning',
        click: 'modelVars.status = 1',
      },
    ]

    $scope.renderStaticAmount = function (value) {
      if (value === undefined) {
        return '-'
      }
      if (value === Infinity) {
        return 'kr ∞'
      } else if (value === -Infinity) {
        return 'kr -∞'
      } else {
        return 'kr ' + danishLocaleNumber(Math.ceil(value))
      }
    }
    $scope.renderStaticPct = function (value) {
      return value === undefined ? '-' : (value * 100).toFixed(2).replace('.', ',') + ' %'
    }
    $scope.renderStaticPct3 = function (value) {
      return value === undefined ? '-' : (value * 100).toFixed(3).replace('.', ',') + ' %'
    }
    $scope.renderStaticDecimal = function (value) {
      return value === undefined ? '-' : value.toFixed(1).replace('.', ',') + ''
    }
    $scope.renderAmount = function (value) {
      return value === undefined ? '-' : danishLocaleNumber(Math.ceil(value))
    }
    $scope.renderPct = function (value) {
      return value === undefined ? '-' : (value * 100).toFixed(2).replace('.', ',')
    }
    $scope.renderPct3 = function (value) {
      return value === undefined ? '-' : (value * 100).toFixed(3).replace('.', ',')
    }
    $scope.renderDecimal = function (value, decimals) {
      return value === undefined ? '-' : value.toFixed(decimals).replace('.', ',')
    }
    $scope.renderDate = function(date, withTime) {
      return date === undefined ? '-' : dateToDanish(date, withTime)
    }
    $scope.renderOption = function(varName, value) {
      const opts = $scope.options[varName]
      if (!opts) {
        return value
      }

      return opts.filter(o => o.value === value)[0]?.option ?? value
    }
    var justSaved = false

    var watches = []

    var unregisterWatches = function () {
      for (var i in watches) {
        var temp = watches[i]
        temp()
      }
      watches = []
    }

    var registerWatches = function () {
      unregisterWatches()

      watches.push(
        $scope.$watch('modelVars.insuranceCompanyId', function (val, oldVal) {
          if ($scope.modelVars.insuranceCompanyId != $scope.engine.eval('forsikringFaellespoliceSelskab')) {
            $scope.modelVars.faellespoliceValgt = false
          }
        }),
      )

      watches.push(
        $scope.$watch('modelVars.nyBil', function (val, oldVal) {
          if ($scope.modelVars.nyBil) {
            $scope.modelVars.carGarantieProduct = 3
            $scope.modelVars.carGarantieForlaengelse = false
            $scope.modelVars.carGarantieForsikringsperiode = 36
            $scope.modelVars.carGarantieFabriksgaranti = 24
          } else {
            $scope.modelVars.carGarantieProduct = 0
            $scope.modelVars.carGarantieForlaengelse = false
            $scope.modelVars.carGarantieForsikringsperiode = 24
            $scope.modelVars.carGarantieFabriksgaranti = 0
          }
        }),
      )

      watches.push(
        $scope.$watch('modelVars.carGarantie', function (val, oldVal) {
          if (!$scope.modelVars.carGarantie) {
            if ($scope.modelVars.nyBil) {
              $scope.modelVars.carGarantieProduct = 3
              $scope.modelVars.carGarantieForlaengelse = false
              $scope.modelVars.carGarantieForsikringsperiode = 36
              $scope.modelVars.carGarantieFabriksgaranti = 24
            } else {
              $scope.modelVars.carGarantieProduct = 0
              $scope.modelVars.carGarantieForlaengelse = false
              $scope.modelVars.carGarantieForsikringsperiode = 24
              $scope.modelVars.carGarantieFabriksgaranti = 0
            }
          }
        }),
      )
      watches.push(
        $scope.$watch('modelVars.carGarantieForsikringsperiode', function (val, oldVal) {
          if (
            $scope.modelVars.carGarantieForsikringsperiode !== $scope.engine.eval('carGarantieMaxForsikringsperiode')
          ) {
            $scope.modelVars.carGarantieForlaengelse = false
          }
        }),
      )

      watches.push(
        $scope.$watch('modelVars.leasingtype', function (val, oldVal) {
          if (val) {
            $scope.setUseFoersteYdelseExMomsPctWrite(false)
            $scope.setUseRestvaerdiCalc('ydelse')
          }
        }),
      )

      watches.push(
        $scope.$watch('modelVars.faellespoliceValgt', function (val, oldVal) {
          if (val === oldVal) return // ignore if called on initialization

          if (val) {
            $scope.modelVars.insuranceCompanyId = $scope.engine.eval('forsikringFaellespoliceSelskab')

            if ($scope.modelVars.isSplitleasing) {
              $scope.modelVars.gpsFirmaKode = serverVars.gpsFirmaKodeSplit
              $scope.modelVars.gpsMedKoerebog = true
            } else {
              $scope.modelVars.gpsFirmaKode =
                $scope.engine.eval('forsikringFaellespoliceGpsGraense') <= $scope.engine.eval('fakturaprisExMoms')
                  ? serverVars.gpsFirmaKodeFlex
                  : null
              $scope.modelVars.gpsMedKoerebog = false
            }
          } else {
            if ($scope.modelVars.insuranceCompanyId == $scope.engine.eval('forsikringFaellespoliceSelskab')) {
              $scope.modelVars.insuranceCompanyId = null
            }

            if ($scope.modelVars.isSplitleasing) {
              $scope.modelVars.gpsFirmaKode = serverVars.gpsFirmaKodeSplit
              $scope.modelVars.gpsMedKoerebog = true
            } else {
              $scope.modelVars.gpsFirmaKode = null
              $scope.modelVars.gpsMedKoerebog = false
            }
          }
        }),
      )

      watches.push(
        $scope.$watchCollection('modelVars', function (newVar, oldVar) {
          if (!$scope.formIsLoaded) return
          $scope.engine.clearExpressionCache()

          if ($scope.form.$dirty) {
            //} && !justSaved) {
            $scope.setGlobalAlert('')
          }
          if (justSaved) {
            justSaved = false
          }
          // 1) kald kun autostuff

          for (var i in engine.resetOrdering) {
            var varName = engine.resetOrdering[i]
            var rs = $scope.variableDefs[varName].reset
            var changeInResetVarFound = false
            for (var i in rs) {
              var val = newVar[rs[i]]
              var oldVal = oldVar[rs[i]]
              if (val === oldVal) continue // ignore if called on initialization
              if (val instanceof Date && oldVal instanceof Date && val.getTime() === oldVal.getTime()) continue
              changeInResetVarFound = true
              break
            }
            if (changeInResetVarFound) {
              var f = $scope.autostuffWatches[varName]
              if (f === undefined) {
                throw new Error('missing autowatch ' + varName)
              }
              f()
            }
          }
        }),
      )

      watches.push(
        $scope.$watch('modelVars.knaekleasing', function (val, oldVal) {
          if (val === oldVal) return // ignore if called on initialization
          if (!$scope.formIsLoaded) return

          if (val == true) {
            $scope.modelVars.useLoebetidWrite = true

            if ($scope.modelVars.useRestvaerdiCalc != 'restvaerdi') {
              var temp = $scope.engine.eval('restvaerdi')
              if (isEmpty(temp)) {
                $scope.modelVars.restvaerdiExMomsWrite = undefined
              } else {
                $scope.modelVars.restvaerdiExMomsWrite = temp
              }
              $scope.modelVars.useRestvaerdiCalc = 'restvaerdi'
            }
          }
        }),
      )

      watches.push(
        $scope.$watch('modelVars.productId', function (val, oldVal) {
          if (val === oldVal) return // ignore if called on initialization
          if (!$scope.formIsLoaded) return

          // reset
          if (!$scope.engine.eval('productCompatibleWithLeasingType')) {
            $scope.modelVars.leasingtype = $scope.engine.eval('leasingtype.default')
          }

          if ($scope.hasAutoItCase && $scope.modelVars.leasingtype) {
            $scope.modelVars.useRestvaerdiCalc = 'matrix'
            $scope.modelVars.regTax = 0
          } else {
            $scope.modelVars.useRestvaerdiCalc = 'restvaerdi'
          }

          if (!$scope.engine.eval('productCompatibleWithRegTax')) {
            $scope.modelVars.regTax = $scope.engine.eval('regTax.default')
          }
          if (!$scope.engine.eval('productCompatibleWithPurchasedAs')) {
            $scope.modelVars.nyBil = $scope.engine.eval('nyBil.default')
          }
          if (!$scope.engine.eval('productCompatibleWithRenewalType')) {
            $scope.modelVars.genleasing = $scope.engine.eval('genleasing.default')
          }
          if (!$scope.engine.eval('productCompatibleWithCustomerType')) {
            $scope.modelVars.kundetype = $scope.engine.eval('kundetype.default')
          }
          if (!$scope.engine.eval('productCompatibleWithLicensePlate')) {
            $scope.modelVars.nummerpladeFarve = $scope.engine.eval('nummerpladeFarve.default')
          }

          $scope.modelVars.loebetidWrite = $scope.engine.eval($scope.variableDefs.loebetidWrite.defaultExpr)
          $scope.modelVars.betalingFoersteGang = $scope.engine.eval($scope.variableDefs.betalingFoersteGang.defaultExpr)
          console.log('betalingFoersteGang CASE 1', $scope.modelVars.betalingFoersteGang)
          $scope.modelVars.overkoerselKmPris = $scope.engine.eval($scope.variableDefs.overkoerselKmPris.defaultExpr)
          $scope.modelVars.totalprisMoms = $scope.engine.eval($scope.variableDefs.totalprisMoms.defaultExpr)
          $scope.modelVars.dokumentgebyrOpkraevesSeparat = $scope.engine.eval(
            $scope.variableDefs.dokumentgebyrOpkraevesSeparat.defaultExpr,
          )
          $scope.modelVars.deklarationForsikringOpkraevesSeparat = $scope.engine.eval(
            $scope.variableDefs.deklarationForsikringOpkraevesSeparat.defaultExpr,
          )

          $scope.modelVars.productIdChanged = true
          console.log('changed product to', val, 'final kundetype', $scope.modelVars.kundetype)
        }),
      )

      watches.push(
        $scope.$watch(
          '[modelVars.isSplitleasing, modelVars.isSeasonLeasing, modelVars.regTax]',
          function ([isSplitLeasing, isSeasonLeasing, regTax], [oldIsSplitLeasing, oldIsSeasonLeasing, oldRegTax]) {
            if (isSplitLeasing === oldIsSplitLeasing && isSeasonLeasing === oldIsSeasonLeasing && regTax === oldRegTax)
              return // ignore if called on initialization
            if (!$scope.formIsLoaded) return

            if (regTax !== 1) {
              console.log('changed insurance company!')
              $scope.modelVars.forsikringFriskade = true

              if (isSplitLeasing) {
                $scope.modelVars.faellespoliceValgt = true
                $scope.modelVars.insuranceCompanyId = $scope.engine.eval('forsikringFaellespoliceSelskab')

                $scope.modelVars.gpsFirmaKode = serverVars.gpsFirmaKodeSplit
                $scope.modelVars.gpsMedKoerebog = true
              } else {
                $scope.modelVars.gpsFirmaKode =
                  $scope.engine.eval('forsikringFaellespoliceGpsGraense') <= $scope.engine.eval('fakturaprisExMoms')
                    ? serverVars.gpsFirmaKodeFlex
                    : null
                $scope.modelVars.gpsMedKoerebog = false
              }
            }
            if (regTax === 1 && regTax !== oldRegTax) {
              $scope.modelVars.faellespoliceValgt = false
              $scope.modelVars.insuranceCompanyId = ''
              $scope.modelVars.forsikringFriskade = false

              $scope.modelVars.gpsFirmaKode = null
              $scope.modelVars.gpsMedKoerebog = false
            }

            // be aware that this is only relevant when changing a loaded formular.
            // defaultValue in ComputationEngine sets this otherwise
            // if (val == 2 || val >= 4) {
            //     $scope.modelVars.kundetype = true;
            // }
            if ($scope.modelVars.kundetype === true) {
              // erhverv
              $scope.modelVars.meddebitor = isSplitLeasing
            }
            if (regTax === 1) {
              modelVars.varevogn = true
            }
            if (regTax === 1) {
              // på leasing-productIder skal løbetiden specificeres direkte
              modelVars.useLoebetidWrite = true
            } else {
              // på finansierings-productIds skal restværdiCalc ikke bruges
              modelVars.useRestvaerdiCalc = 'restvaerdi'
            }

            if (regTax === 1) {
              // på leasing-productIder skal løbetiden specificeres direkte
              modelVars.fastRente = false
            }
            modelVars.tidligereIndregistreret = true
            if (isSeasonLeasing && !oldIsSeasonLeasing) {
              modelVars.sommerbilAntalSaesoner = 1
            }
            if (!isSeasonLeasing && oldIsSeasonLeasing) {
              modelVars.sommerbilAntalSaesoner = 0
            }
            $scope.modelVars.loebetidWrite = $scope.engine.eval($scope.variableDefs.loebetidWrite.defaultExpr)
            $scope.modelVars.betalingFoersteGang = $scope.engine.eval($scope.variableDefs.betalingFoersteGang.defaultExpr)
            $scope.modelVars.overkoerselKmPris = $scope.engine.eval($scope.variableDefs.overkoerselKmPris.defaultExpr)
            $scope.modelVars.totalprisMoms = $scope.engine.eval($scope.variableDefs.totalprisMoms.defaultExpr)
            $scope.modelVars.dokumentgebyrOpkraevesSeparat = $scope.engine.eval(
              $scope.variableDefs.dokumentgebyrOpkraevesSeparat.defaultExpr,
            )
            $scope.modelVars.deklarationForsikringOpkraevesSeparat = $scope.engine.eval(
              $scope.variableDefs.deklarationForsikringOpkraevesSeparat.defaultExpr,
            )

            $scope.modelVars.productIdChanged = true
          },
          true,
        ),
      )

      watches.push(
        // 'fakturaprisExMoms', 'leveringsomkostningerExMoms', 'eftermonteretUdstyr', 'nummerplader'
        $scope.$watchCollection(
          '[modelVars.fakturaprisExMoms,modelVars.leveringsomkostningerExMoms,modelVars.eftermonteretUdstyr,modelVars.nummerplader]',
          function () {
            if (modelVars.regTax === 0 && !modelVars.isSplitleasing) {
              if (modelVars.faellespoliceValgt) {
                $scope.modelVars.gpsFirmaKode =
                  $scope.engine.eval('forsikringFaellespoliceGpsGraense') <= $scope.engine.eval('fakturaprisExMoms')
                    ? serverVars.gpsFirmaKodeFlex
                    : null
              }
            }
          },
        ),
      )

      watches.push(
        $scope.$watch('modelVars.kundetype', function (val, oldVal) {
          if (val === oldVal) return // ignore if called on initialization
          if (!$scope.formIsLoaded) return
          if (val == true) {
            // erhverv
            modelVars.meddebitor = modelVars.isSplitleasing
          }
          if (oldVal === true && val === false) {
            modelVars.ejerCpr1 = ''
            modelVars.ejerNavn1 = ''
            modelVars.ejerCpr2 = ''
            modelVars.ejerNavn2 = ''
            modelVars.ejerCpr3 = ''
            modelVars.ejerNavn3 = ''
            modelVars.ejerCpr4 = ''
            modelVars.ejerNavn4 = ''
          }
        }),
      )
      watches.push(
        $scope.$watch('modelVars.anviserForhandler', function (val, oldVal) {
          if (val === oldVal) return // ignore if called on initialization
          if (!$scope.formIsLoaded) return
          if (val == 1) {
            // forhandler
            modelVars.anviserKontraktsaet = false
          } else {
            modelVars.anviserKontraktsaet = true

            if (!$scope.userInfo.isSuperAdmin) {
              modelVars.overkoerselKmPris = $scope.engine.eval('overkoerselKmPris.default')
            }
          }
        }),
      )

      watches.push(
        $scope.$watch('modelVars.meddebitor', function (val, oldVal) {
          if (val === oldVal) return // ignore if called on initialization
          if (!$scope.formIsLoaded) return
          if (val == 1) {
            if ($scope.modelVars.adresse2 == '') {
              $scope.modelVars.adresse2 = $scope.modelVars.adresse
            }
            if ($scope.modelVars.postnr2 == null) {
              $scope.modelVars.postnr2 = $scope.modelVars.postnr
            }
          }
        }),
      )
      watches.push(
        $scope.$watch('modelVars.cvr', function (cvr) {
          if (CVR_REGEXP.test(cvr)) {
            $scope.cvrResult = {
              register: 'CVR',
              type: 'wait',
            }

            $http.post('/api/CVRLookup/' + cvr).success(function (res) {
              $scope.cvrResult = res
              var virksomhedsform = getCvrValue('Virksomhedsform', $scope.cvrResult)
              $scope.cvrEnkeltmandsvirksomhed =
                virksomhedsform !== null &&
                virksomhedsform !== 'Aktieselskab' &&
                virksomhedsform !== 'Anpartsselskab' &&
                virksomhedsform !== 'Kommanditaktieselskab/Partnerselskab' &&
                virksomhedsform !== 'Iværksætterselskab'
              $scope.modelVars.tegningsregel = getCvrValue('Tegningsregel', $scope.cvrResult)
              $scope.modelVars.enkeltmandsvirksomhed = $scope.cvrEnkeltmandsvirksomhed
              $scope.modelVars.regnskabMangler = res.reports == 0
            })
          } else {
            $scope.cvrResult = {}
            $scope.cvrEnkeltmandsvirksomhed = false
            $scope.modelVars.enkeltmandsvirksomhed = false
          }
        }),
      )
      watches.push(
        $scope.$watch(
          function () {
            if ($scope.modelVars.anviserForhandler == 3) {
              return $scope.modelVars.anviserCvr
            }
            return undefined
          },
          function (cvr) {
            if (CVR_REGEXP.test(cvr)) {
              $scope.cvrResultAnviser = {
                register: 'CVR',
                type: 'wait',
              }
              $http.post('/api/CVRLookup/' + cvr).success(function (res) {
                $scope.cvrResultAnviser = res
                var virksomhedsform = getCvrValue('Virksomhedsform', $scope.cvrResultAnviser)
                $scope.modelVars.cvrEnkeltmandsvirksomhedAnviser =
                  virksomhedsform !== null &&
                  virksomhedsform !== 'Aktieselskab' &&
                  virksomhedsform !== 'Anpartsselskab' &&
                  virksomhedsform !== 'Kommanditaktieselskab/Partnerselskab' &&
                  virksomhedsform !== 'Iværksætterselskab'
              })
            } else {
              $scope.cvrResultAnviser = {}
              $scope.modelVars.cvrEnkeltmandsvirksomhedAnviser = false
            }
          },
        ),
      )
      watches.push(
        $scope.$watch('modelVars.kautionist', function (val, oldVal) {
          if (val === oldVal) return // ignore if called on initialization

          if (val == 0) {
            $scope.modelVars.kautionist2 = 0
          }
        }),
      )

      watches.push(
        $scope.$watchCollection(
          '[modelVars.kundetype, modelVars.stelnummer, modelVars.cvr, modelVars.cpr, modelVars.cpr2]',
          function () {
            if (!$scope.aftalenr && !modelVars.genleasingLocked) {
              var kundenr = modelVars.kundetype ? modelVars.cvr : modelVars.cpr

              var updateGenleasing = function (res, anslaaetAndelPrivatkoersel) {
                $scope.engine.clearExpressionCache()
                if (res) {
                  const value = res.value
                  modelVars.genleasing = true
                  modelVars.genleasingKontraktnr = value.kontraktnr
                  modelVars.genleasingGps = value.produkt == 'Split' || value.produkt == 'Sæson-Split'
                  modelVars.navRestgaeld = Math.floor(value.restgaeld)
                  modelVars.navRestvaerdi = Math.floor(value.restvaerdi)
                  if (anslaaetAndelPrivatkoersel) {
                    $scope.modelVars.anslaaetAndelPrivatkoersel = anslaaetAndelPrivatkoersel
                    $scope.genleasingAndelPrivatKoersel = anslaaetAndelPrivatkoersel
                  } else {
                    $scope.genleasingAndelPrivatKoersel = null
                  }
                  if (value.faellespolice) {
                    modelVars.genleasingFaellespolice = value.faellespolice
                    modelVars.faellespoliceValgt = value.faellespolice
                    if (modelVars.faellespoliceValgt) {
                      modelVars.forsikringsvaerdiWrite = res.forsikringsvaerdi
                      modelVars.forsikringIkraft = Date.parse(value.forsikringIkraft)
                    }
                  }
                } else {
                  modelVars.genleasing = false
                  modelVars.genleasingKontraktnr = null
                  modelVars.genleasingGps = false
                  $scope.genleasingAndelPrivatKoersel = null
                }
              }

              updateGenleasing(null)
            }
          },
        ),
      )

      addRoleWatchers($scope, watches)
    }

    $scope.cvrResult = {}
    $scope.cvrEnkeltmandsvirksomhed = false
    $scope.genleasingAndelPrivatKoersel = null

    var getCvrValue = function (key, obj) {
      if (obj !== undefined) {
        if (obj.sections !== undefined) {
          for (var j in obj.sections) {
            if (obj.sections[j].elements !== undefined) {
              for (var i in obj.sections[j].elements) {
                var elem = obj.sections[j].elements[i]
                if (elem.key == key) {
                  return elem.value[0]
                }
              }
            }
          }
        }
      }
      return null
    }

    $http.post('/api/application/get', param).success(loadForm).error(getFail)

    $scope.setUseFoersteYdelseExMomsPctWrite = function (newVal) {
      var oldVal = modelVars.useFoersteYdelseExMomsPctWrite
      var BELOEB = false
      var PROCENT = true

      if (oldVal === newVal) return

      if (newVal === PROCENT) {
        var temp = $scope.engine.eval('foersteYdelseExMomsPct')
        if (isEmpty(temp)) {
          $scope.modelVars.foersteYdelseExMomsPctWrite = undefined
        } else {
          $scope.modelVars.foersteYdelseExMomsPctWrite = temp
        }
      }
      if (newVal === BELOEB) {
        var temp = $scope.engine.eval('foersteYdelseExMoms')
        if (isEmpty(temp)) {
          $scope.modelVars.foersteYdelseExMomsWrite = undefined
        } else {
          $scope.modelVars.foersteYdelseExMomsWrite = temp
        }
      }
      $scope.modelVars.useFoersteYdelseExMomsPctWrite = newVal
    }

    $scope.setUseRestvaerdiCalc = function (newVal) {
      var oldVal = modelVars.useRestvaerdiCalc
      if (oldVal === newVal) return

      if (newVal === 'ydelse') {
        var temp = $scope.engine.eval('ydelse')
        if (isEmpty(temp)) {
          $scope.modelVars.ydelseWrite = undefined
        } else {
          $scope.modelVars.ydelseWrite = temp
        }
      }

      if (newVal === 'restvaerdi') {
        var temp = $scope.engine.eval('restvaerdi')

        if (isEmpty(temp)) {
          $scope.modelVars.restvaerdiExMomsWrite = undefined
        } else {
          $scope.modelVars.restvaerdiExMomsWrite = temp
        }
      }

      if (newVal === 'afviklingsprocent') {
        var temp = $scope.engine.eval('afviklingsprocent')
        if (isEmpty(temp) || !isFinite(temp)) {
          $scope.modelVars.afviklingsprocentWrite = 0 // percent often ends up in divide by zero case.
        } else {
          $scope.modelVars.afviklingsprocentWrite = temp
        }
      }
      $scope.modelVars.useRestvaerdiCalc = newVal
    }

    $scope.createKeyForUserLink = function () {
      $http.post('/api/application/createKeyForUserLink/' + param.id).success(function (res) {
        $scope.setGlobalAlert('Nøgle blev oprettet!')
      })
    }
    $scope.tempState = { visPrisinfo: false }

    $scope.options = {}

    $scope.options.dokumentgebyrOpkraevesSeparat = [
      { value: false, option: 'Medfinansieres' },
      { value: true, option: 'Opkræves' },
    ]

    $scope.options.ignoreCasehubCreation = [
      { value: false, option: 'Kundelink afsendes' },
      { value: true, option: 'Kundelink afsendes ikke' },
    ]

    $scope.options.nyBil = [
      { value: true, option: 'Ny' },
      { value: false, option: 'Brugt' },
    ]
    $scope.predPurchasedAs = function (elem) {
      if (elem.value === true) {
        return $scope.engine.eval('productHasPurchasedAsNew')
      }
      if (elem.value === false) {
        return $scope.engine.eval('productHasPurchasedAsUsed')
      }
      return true
    }
    $scope.singlePurchasedAs = function () {
      return ($scope.engine.eval('productHasPurchasedAsNew') ? 1 : 0) + ($scope.engine.eval('productHasPurchasedAsUsed') ? 1 : 0) == 1
    }
    $scope.options.roadsideAssistance = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.friKm = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.nummerpladeEU = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.nummerpladeFarve = [
      { value: 0, option: 'Hvid' },
      { value: 1, option: 'Gul' },
      { value: 2, option: 'Hvid/Gul' },
    ]

    $scope.predLicensePlate = function (elem) {
      if (elem.value === 0) {
        return $scope.engine.eval('productHasLicensePlateWhite')
      }
      if (elem.value === 1) {
        return $scope.engine.eval('productHasLicensePlateYellow')
      }
      if (elem.value === 2) {
        return $scope.engine.eval('productHasLicensePlateWhiteYellow')
      }
      return true
    }
    $scope.singleLicensePlate = function () {
      return (
        ($scope.engine.eval('productHasLicensePlateWhite') ? 1 : 0) +
          ($scope.engine.eval('productHasLicensePlateYellow') ? 1 : 0) +
          ($scope.engine.eval('productHasLicensePlateWhiteYellow') ? 1 : 0) ==
        1
      )
    }

    $scope.options.leasingtype = [
      { value: false, option: 'Finansiel' },
      { value: true, option: 'Operationel' },
    ]
    $scope.predLeasingType = function (elem) {
      if (elem.value === false) {
        return $scope.engine.eval('productHasLeasingTypeFinancial')
      }
      if (elem.value === true) {
        return $scope.engine.eval('productHasLeasingTypeOperating')
      }
      return true
    }
    $scope.singleLeasingType = function () {
      return (
        ($scope.engine.eval('productHasLeasingTypeFinancial') ? 1 : 0) +
          ($scope.engine.eval('productHasLeasingTypeOperating') ? 1 : 0) ==
        1
      )
    }
    $scope.options.minilease = [
      { value: false, option: 'Ej Minilease' },
      { value: true, option: 'Minilease' },
    ]
    $scope.options.gaeldsbrev = [
      { value: false, option: 'Med ejendomsforbehold' },
      { value: true, option: 'Gældsbrev' },
    ]
    $scope.options.kundetype = [
      { value: false, option: 'Privatkunde' },
      { value: true, option: 'Erhvervskunde' },
    ]
    $scope.predCustomerType = function (elem) {
      if (elem.value === false) {
        return $scope.engine.eval('productHasCustomerTypePerson')
      }
      if (elem.value === true) {
        return $scope.engine.eval('productHasCustomerTypeCompany')
      }
      return true
    }
    $scope.singleCustomerType = function () {
      return (
        ($scope.engine.eval('productHasCustomerTypePerson') ? 1 : 0) +
          ($scope.engine.eval('productHasCustomerTypeCompany') ? 1 : 0) ==
        1
      )
    }
    $scope.options.haftBilFoer = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.demobil = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.tilladPrivatAnviser = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.saesonleasingGentegning = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.meddebitor = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.boligtype = [
      { value: 0, option: 'Ejerbolig' },
      { value: 1, option: 'Lejebolig' },
      { value: 2, option: 'Andelsbolig' },
    ]
    $scope.options.fastRente = [
      { value: false, option: 'Variabel rente' },
      { value: true, option: 'Fast rente' },
    ]
    $scope.options.tidligereIndregistreret = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.anviserForhandler = [
      { value: 0, option: 'Leasingtager' },
      { value: 1, option: 'Forhandler' },
      { value: 3, option: 'Anden (erhverv)' },
    ]
    $scope.predRepurchaseObligation = function (elem) {
      if (elem.value === 1) {
        return $scope.engine.eval('useRepurchaseObligationDealerProduct')
      }
      if (elem.value === 3) {
        return $scope.engine.eval('useRepurchaseObligationOtherProduct')
      }
      return true
    }
    $scope.singleRepurchaseObligation = function () {
      return (
        1 +
          ($scope.engine.eval('useRepurchaseObligationDealerProduct') ? 1 : 0) +
          ($scope.engine.eval('useRepurchaseObligationOtherProduct') ? 1 : 0) ==
        1
      )
    }

    $scope.options.anviserKontraktsaet = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.anviser2 = [
      { value: 0, option: 'Ingen' },
      { value: 2, option: 'Anden (erhverv)' },
    ]
    $scope.options.anviser2Kontraktsaet = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.fastPeriode = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.aldersbetingelseDeaktiveret = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.cprUdenMod11 = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.brugtmoms = [
      { value: false, option: 'Ekskl. moms' },
      { value: true, option: 'Inkl. brugtmoms' },
    ]
    $scope.options.leasinggiverBetalerNummerplader = [
      { value: 0, option: 'Forhandler' },
      { value: 1, option: $interop.prettyModuleName },
      { value: 2, option: 'Opkræves separat' },
    ]
    $scope.options.varevogn = [
      { value: false, option: 'Bil' },
      { value: true, option: 'Varevogn' },
    ]
    $scope.options.forsikringAldersgraense = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.forsikringSkadesfri = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.forsikringStatsborger = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.forsikringUndtagelse = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.forsikringFriskade = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.campingFastligger = [
      { value: false, option: 'Ja, vognen skal have nummerplader på' },
      { value: true, option: 'Nej, kunden er fastligger' },
    ]
    $scope.options.carGarantieProductBrugt = [
      { value: 0, option: 'Basic 100' },
      { value: 1, option: 'Comfort 100' },
      { value: 2, option: 'Comfort Plus 100' },
    ]
    $scope.options.carGarantieProductNy = [{ value: 3, option: 'Comfort Plus 150' }]
    $scope.options.carGarantieForsikringsperiode = [
      { value: 12, option: '1 år' },
      { value: 24, option: '2 år' },
    ]
    $scope.options.carGarantieForsikringsperiodeNyBil = [
      { value: 12, option: '1 år' },
      { value: 24, option: '2 år' },
      { value: 36, option: '3 år' },
    ]
    $scope.options.carGarantieFabriksgaranti = [
      { value: 24, option: '2 år' },
      { value: 36, option: '3 år' },
    ]
    $scope.options.gaeldsbrevUdenPant = [
      { value: false, option: 'Med pant' },
      { value: true, option: 'Uden pant' },
    ]
    $scope.options.knaekleasing = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.genberegningRegAfgiftValutabil = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.genberegningRegAfgiftBiludlejningMereEnd2Mdr = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.kautionist = [
      { value: 0, option: 'Ingen kautionist' },
      { value: 2, option: 'Ja, privat kautionist' },
      { value: 3, option: 'Ja, erhvervskautionist' },
    ]
    $scope.options.kautionist2 = [
      { value: 0, option: 'Ingen kautionist 2' },
      { value: 1, option: 'Ja, privat kautionist 2' },
      { value: 2, option: 'Ja, erhvervskautionist 2' },
    ]
    $scope.options.sommerbil = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.stiftelsesprovisionBeloebSpec = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.disableEkstraMaanedRegAfgift = [
      { value: false, option: 'Ekstra måned aktiveret' },
      { value: true, option: 'Ekstra måned deaktiveret' },
    ]
    $scope.options.prisInklAfgift = [
      { value: false, option: 'Ekskl. afgift' },
      { value: true, option: 'Inkl. afgift' },
    ]
    $scope.options.faellespoliceValgt = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.genleasing = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.predRenewalType = function (elem) {
      if (elem.value === false) {
        return $scope.engine.eval('productHasRenewalTypeNew')
      }
      if (elem.value === true) {
        return $scope.engine.eval('productHasRenewalTypeRenewal')
      }
      return true
    }
    $scope.singleRenewalType = function () {
      return (
        ($scope.engine.eval('productHasRenewalTypeNew') ? 1 : 0) + ($scope.engine.eval('productHasRenewalTypeRenewal') ? 1 : 0) == 1
      )
    }
    $scope.options.genleasingGps = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.afgiftBeregnetAf = [
      { value: 0, option: 'Ingen' },
      { value: 1, option: 'Det har jeg' },
      { value: 2, option: 'Det har Autohauz Leasing' },
    ]
    $scope.options.afgiftBeregnetAfReadOnly = [
      { value: 0, option: 'Ingen' },
      { value: 1, option: 'Det har jeg' },
      { value: 2, option: 'Det har Autohauz Leasing' },
      { value: 4, option: 'ASG Afgiftsvurdering' },
    ]
    $scope.options.braendstofstype = [
      { value: 0, option: 'Ingen' },
      { value: 1, option: 'Benzin' },
      { value: 2, option: 'Diesel' },
      { value: 3, option: 'El' },
      { value: 4, option: 'Hybrid' },
      { value: 5, option: 'Hybrid (plugin)' },
    ]
    $scope.options.braendstof = [
      { value: 0, option: 'Benzin / Diesel' },
      { value: 1, option: 'Hybrid' },
      { value: 2, option: 'Hybrid (plugin)' },
      { value: 3, option: 'El' },
    ]
    $scope.options.maskinrumKautionistAnviser = [
      { value: false, option: 'Benyt standardindstilling' },
      { value: true, option: 'Ja, individuelt godkendt' },
    ]
    $scope.options.allowEnkeltmandsfirmaAnviserMaskinrum = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.maalemetode = [
      { value: '', option: 'Vælg' },
      { value: 'WLTP', option: 'WLTP' },
      { value: 'NEDC', option: 'NEDC' },
    ]
    $scope.options.deklarationForsikringOpkraevesSeparat = [
      { value: false, option: 'Medfinansieres' },
      { value: true, option: 'Opkræves' },
    ]
    $scope.options.useFoersteYdelseExMomsPctWrite = [
      { value: false, option: 'Beløb' },
      { value: true, option: 'Procent' },
    ]
    $scope.options.useRestvaerdiCalc = [
      { value: 'restvaerdi', option: 'Restværdi' },
      { value: 'afviklingsprocent', option: 'Afviklingsprocent' },
      { value: 'ydelse', option: 'Ydelse' },
    ]
    $scope.options.vehicleOrigin = [
      {value: false, option: 'Hjemtaget'},
      {value: true, option: 'Lager el. andet leasingselskab'}
    ]

    $scope.options.regTax = [
      { value: 0, option: 'Forholdsmæssig afgift' },
      { value: 1, option: 'Fuld afgift' },
      { value: 2, option: 'Ikke indregistreret' },
    ]

    $scope.options.carGarantieIsFinanced = [
      { value: false, option: 'Opkræves' },
      { value: true, option: 'Medfinansieres' },
    ]
    $scope.options.gpsIsCharged = [
      { value: false, option: 'Medfinansieres' },
      { value: true, option: 'Opkræves' },
    ]

    $scope.options.deliveryExpensesType = [
      { value: false, option: 'Medfinansieres' },
      { value: true, option: 'Opkræves separat' },
    ]
    $scope.options.licensePlateRegistrationType = [
      { value: 0, option: 'Ingen' },
      { value: 1, option: 'Registrering' },
      { value: 2, option: 'Omregistrering' },
      { value: 3, option: 'Andet beløb' }
    ]

    $scope.predRegTax = function (elem) {
      if (elem.value === 0) {
        return $scope.engine.eval('productHasRegTaxProportionate')
      }
      if (elem.value === 1) {
        return $scope.engine.eval('productHasRegTaxFull')
      }
      if (elem.value === 2) {
        return $scope.engine.eval('productHasRegTaxNone')
      }
      return true
    }
    $scope.singleRegTax = function () {
      return (
        ($scope.engine.eval('productHasRegTaxProportionate') ? 1 : 0) +
          ($scope.engine.eval('productHasRegTaxFull') ? 1 : 0) +
          ($scope.engine.eval('productHasRegTaxNone') ? 1 : 0) ==
        1
      )
    }

    $scope.parseInt = parseInt
    $scope.anviserCvrHasError = function () {
      return (
        !$scope.modelVars.allowEnkeltmandsfirmaAnviserMaskinrum &&
        $scope.modelVars.anviserForhandler == 3 &&
        !$scope.modelVars.kundetype &&
        $scope.modelVars.cvrEnkeltmandsvirksomhedAnviser
      )
    }

    $scope.convertToSuperDraft = function () {
      $http.post('/api/application/convertToSuperDraft/' + param.id).success(function (res) {
        $scope.setGlobalAlert('Ansøgningen blev konverteret til superkladde!')
        $scope.setKeepAlert(false)
        $http.post('/api/application/get', param).success(loadForm).error(getFail)
      })
    }
    $scope.convertToDraft = function () {
      $http.post('/api/application/convertToDraft/' + param.id).success(function (res) {
        $scope.setGlobalAlert('Ansøgningen blev konverteret til kladde!')
        $scope.setKeepAlert(false)
        $http.post('/api/application/get', param).success(loadForm).error(getFail)
      })
    }

    $scope.validateEmail = function (email) {
      if (email === undefined) return true

      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }

    $scope.validateEmailList = function (str) {
      if (str === undefined) return true
      if (str.trim() == '') return true

      var xs = str.split(',')
      for (var i in xs) {
        var x = xs[i].trim()
        if (!$scope.validateEmail(x)) {
          return false
        }
      }
      return true
    }

    $scope.mailExternal = function (mail, cc, subject, content) {
      var temp = {}
      if ($scope.engine !== undefined) {
        var temp = $scope.engine.getValues()
        for (var v in temp) {
          var d = temp[v]
          temp[v] = d instanceof Date ? dateToYMD(d) : d
        }
      }

      $http
        .post('/api/application/sendExternalMail/' + param.id, {
          mail: mail,
          cc: cc,
          subject: subject,
          content: content,
          values: temp,
          id: $scope.id,
        })
        .success(function (res) {
          $scope.setGlobalAlert('Sagen blev afsendt til ' + mail)
          $scope.setKeepAlert(false)
          $http.post('/api/application/get', param).success(loadForm).error(getFail)
        })
    }

    $scope.mailContract = function (mail, cc, subject, content, attachmentsState, subdocumentsState) {
      var temp = {}
      if ($scope.engine !== undefined) {
        var temp = $scope.engine.getValues()
        for (var v in temp) {
          var d = temp[v]
          temp[v] = d instanceof Date ? dateToYMD(d) : d
        }
      }

      $http
        .post('/api/application/sendContractMail/' + param.id, {
          mail: mail,
          cc: cc,
          subject: subject,
          content: content,
          attachments: attachmentsState,
          subdocuments: subdocumentsState,
          downloadButtonState: $scope.downloadButtonState,
          values: temp,
          id: $scope.id,
          aftalenr: $scope.aftalenr,
        })
        .success(function (res) {
          $scope.setGlobalAlert('Kontrakten blev afsendt til ' + mail)
          $scope.setKeepAlert(false)
        })
    }

    $scope.mailOfferFiles = []
    $scope.mailOfferFilesSelect = function (files) {
      $scope.mailOfferFiles = files
    }

    $scope.mailOffer = function (mail, cc, subject, content, attachmentsState) {
      if ($scope.mailOfferFiles.length > 0) {
        $upload
          .upload({
            url: '/api/applicationUpload/uploadMaterialsTemp',
            file: $scope.mailOfferFiles,
          })
          .error(function () {
            $scope.errorMsg = 'Filer kunne ikke uploades.'
          })
          .success(function (data, status, headers, config) {
            $scope.mailOfferBase(mail, cc, subject, content, attachmentsState, data)
          })
      } else {
        $scope.mailOfferBase(mail, cc, subject, content, attachmentsState, undefined)
      }
    }
    $scope.mailOfferBase = function (mail, cc, subject, content, attachmentsState, data) {
      var temp = {}
      if ($scope.engine !== undefined) {
        var temp = $scope.engine.getValues()
        for (var v in temp) {
          var d = temp[v]
          temp[v] = d instanceof Date ? dateToYMD(d) : d
        }
      }

      $http
        .post('/api/application/sendOfferMail', {
          mail: mail,
          cc: cc,
          subject: subject,
          content: content,
          attachments: attachmentsState,
          downloadButtonState: $scope.downloadButtonState,
          values: temp,
          id: $scope.id,
          uploaddir: data,
        })
        .success(function (res) {
          $scope.setGlobalAlert('Tilbuddet blev afsendt til ' + mail)
          $scope.setKeepAlert(false)
        })
    }
    $scope.mailMaterialRequest = function (mail, cc, subject, content) {
      var temp = {}
      if ($scope.engine !== undefined) {
        var temp = $scope.engine.getValues()
        for (var v in temp) {
          var d = temp[v]
          temp[v] = d instanceof Date ? dateToYMD(d) : d
        }
      }

      $http
        .post('/api/application/sendMaterialRequestMail', {
          mail: mail,
          cc: cc,
          subject: subject,
          content: content,
          values: temp,
          id: $scope.id,
        })
        .success(function (res) {
          $scope.setGlobalAlert('Materialeforespørgselen blev afsendt til ' + mail)
          $scope.setKeepAlert(false)
        })
    }

    $scope.mailExternalModal = function () {
      var modalScope = $scope.$new()

      modalScope.mail = $scope.userInfo.externalMail
      $http.get('/api/application/getExternalMail/' + $scope.id).success(function (res) {
        modalScope.subject = res.subject
        modalScope.content = res.content
      })
      $modal({ template: applicationMailExternalTemplate, show: true, scope: modalScope })
    }

    $scope.asgModal = function () {
      var modalScope = $scope.$new()
      modalScope.modelVars.asgCO2Udslip = 0
      modalScope.modelVars.asgMaalemetode = ''
      $modal({ template: asgModal, show: true, scope: modalScope })
    }

    $scope.mailContractModal = function () {
      var modalScope = $scope.$new()

      modalScope.cc = ''
      modalScope.mail = $scope.modelVars.mail
      $http.get('/api/application/getContractMail/' + $scope.id).success(function (res) {
        modalScope.subject = res.subject
        modalScope.content = res.content
      })
      var subdocuments = [
        { name: 'supplerendeoplysninger', friendly: 'Supplerende oplysninger' }, // fælles
      ]
        .concat(
          isFinansiering()
            ? $scope.modelVars.gaeldsbrev
              ? [
                  { name: 'laaneaftale', friendly: 'Låneaftale' },
                  { name: 'ejerpantebrev', friendly: 'Ejerpantebrev' },
                ]
              : [
                  { name: 'kontrakt', friendly: 'Kontrakt' },
                  { name: 'ejendomsforbehold', friendly: 'Ejendomsforbehold' },
                ]
            : [],
        )
        .concat(
          isLeasing()
            ? [
                { name: 'skatLeasingkontrakt', friendly: 'Leasingkontrakt' }, // leasing
                { name: 'skatAllongeSplit', friendly: 'Allonge, SPLITleasing' }, // leasing
                { name: 'skatAllongeAnviser', friendly: 'Anviser køber' }, // leasing
                { name: 'skatAllongeLeverancegodkendelse', friendly: 'Leverancegodkendelse' }, // leasing
                { name: 'alarmopkald', friendly: 'Alarmopkald' }, // leasing
                { name: 'vanvidskoersel', friendly: 'Tro- og loveerklæring om vanvidskørsel' },
              ]
            : [],
        )
        .concat(
          isLeasing() && isKoebstaederne()
            ? [
                { name: 'trooglove', friendly: 'Tro- og loveerklæring' }, // leasing
              ]
            : [],
        )
        .concat([
          { name: 'kautionserklaering', friendly: 'Kautionserklæring' }, // fælles
        ])
        .concat(
          $scope.modelVars.isSplitleasing && !$scope.modelVars.isSeasonLeasing
            ? [{ name: 'braendstofkort', friendly: 'Brændstofkort' }]
            : [],
        )
        .concat([
          { name: 'husstandsoplysninger', friendly: 'Husstandsoplysninger' }, // fælles
          { name: 'pbs', friendly: 'NETS' }, // fælles
        ])
        .concat(isFinansiering() ? [{ name: 'sef', friendly: 'SEF' }] : [])
      modalScope.attachments = [
        {
          name: 'kontrakt',
          friendly: 'Kontrakt MED kundekopi',
        },
        {
          name: 'kontraktexkundekopi',
          friendly: 'Kontrakt UDEN kundekopi',
        },
        {
          name: 'kontraktudvalgte',
          friendly: 'Kontrakt, udvalgte dokumenter',
          subdocuments: subdocuments,
        },
      ]

      if ($scope.modelVars.isSplitleasing && !$scope.modelVars.isSeasonLeasing) {
        modalScope.attachments.push({
          name: 'braendstofkort',
          friendly: 'Bestillingsformular til brændstofkort',
        })
      }

      modalScope.attachments.push({
        name: 'anviserprivatforhandler',
        friendly: 'Anviser køber - sekundær',
      })
      if (isLeasing() && isKoebstaederne()) {
        modalScope.attachments.push({
          name: 'trooglove',
          friendly: 'Tro- og love erklæring*',
        })
      }
      if (isLeasing()) {
        modalScope.attachments.push({
          name: 'vanvidskoersel',
          friendly: 'Tro- og loveerklæring om vanvidskørsel',
        })
      }
      modalScope.attachments.push({
        name: 'offer',
        friendly: 'Finansieringsforslag',
      })

      if (!isLeasing() && $scope.userInfo.isSuperAdmin) {
        modalScope.attachments.push({
          name: 'allongeindfrielse',
          friendly: 'Allonge, indfrielse',
        })
      }

      modalScope.subdocumentsState = {}

      for (var i in modalScope.attachments) {
        var a = modalScope.attachments[i]
        if (a.subdocuments) {
          var obj = {}
          for (var j in a.subdocuments) {
            obj[a.subdocuments[j].name] = false
          }
          modalScope.subdocumentsState[a.name] = obj
        }
      }

      modalScope.attachmentsState = {
        kontrakt: false,
        kontraktexkundekopi: true,
        kontraktudvalgte: false,
        braendstofkort: false,
        kautionserklaering: false,
        anviser: false,
        anviserprivatforhandler: false,
        husstandsoplysninger: false,
        offer: false,
        allongeindfrielse: false,
      }

      $scope.attachmentCount = 1
      modalScope.updateAttachmentCount = function () {
        modalScope.updateSubdocumentValidity()
        var c = 0
        angular.forEach(modalScope.attachmentsState, function (isActive) {
          if (isActive) {
            c++
          }
        })
        $scope.attachmentCount = c
      }

      $scope.subdocumentValidity = true

      modalScope.updateSubdocumentValidity = function () {
        for (var i in modalScope.attachments) {
          var a = modalScope.attachments[i]
          if (a.subdocuments && modalScope.attachmentsState[a.name]) {
            var c = 0
            for (var j in a.subdocuments) {
              if (modalScope.subdocumentsState[a.name][a.subdocuments[j].name]) {
                c++
              }
            }

            if (c == 0) {
              $scope.subdocumentValidity = false
              return
            }
          }
        }

        $scope.subdocumentValidity = true
      }

      $modal({ template: applicationMailContractTemplate, show: true, scope: modalScope })
    }

    $scope.mailOfferModal = function () {
      var modalScope = $scope.$new()

      modalScope.rememberDownloadButtonState = function () {
        $http.post('/api/setDownloadButtonState', $scope.downloadButtonState)
      }

      var temp = {}
      if ($scope.engine !== undefined) {
        var temp = $scope.engine.getValues()
        for (var v in temp) {
          var d = temp[v]
          temp[v] = d instanceof Date ? dateToYMD(d) : d
        }
      }
      modalScope.cc = ''
      modalScope.mail = $scope.modelVars.mail
      $http.post('/api/application/getOfferMail', { values: temp, id: $scope.id }).success(function (res) {
        modalScope.subject = res.subject
        modalScope.content = res.content
      })

      modalScope.attachments = [
        {
          name: 'offer',
          friendly: 'Finansieringsforslag',
        },
        {
          name: 'husstandsoplysninger',
          friendly: 'Husstandsoplysninger',
        },
      ]
      if ($scope.userInfo.isSuperAdmin) {
        modalScope.attachments.push({
          name: 'kautionserklaering',
          friendly: 'Kautionserklæring',
        })
      }
      if ($scope.userInfo.isSuperAdmin) {
        modalScope.attachments.push({
          name: 'kontraktudkast',
          friendly: 'Kontraktudkast',
        })
      }

      modalScope.attachmentsState = {
        offer: true,
        kautionserklaering: false,
        husstandsoplysninger: false,
        kontraktudkast: false,
      }

      $scope.attachmentCount = 1
      modalScope.updateAttachmentCount = function () {
        var c = 0
        angular.forEach(modalScope.attachmentsState, function (isActive) {
          if (isActive) {
            c++
          }
        })
        $scope.attachmentCount = c
      }

      $modal({ template: applicationMailOfferTemplate, show: true, scope: modalScope })
    }

    $scope.mailMaterialRequestModal = function () {
      var modalScope = $scope.$new()

      modalScope.cc = ''
      modalScope.mail = $scope.modelVars.mail

      var temp = {}
      if ($scope.engine !== undefined) {
        var temp = $scope.engine.getValues()
        for (var v in temp) {
          var d = temp[v]
          temp[v] = d instanceof Date ? dateToYMD(d) : d
        }
      }

      $http.post('/api/application/getMaterialRequestMail', { values: temp, id: $scope.id }).success(function (res) {
        modalScope.subject = res.subject
        modalScope.content = res.content
      })

      $modal({ template: applicationMailMaterialRequestTemplate, show: true, scope: modalScope })
    }

    $scope.setInfotext = function (field, description) {
      $http.post('/api/infotext/set/application/' + field, { description: description }).success(function (res) {
        $scope.fieldDescriptions[field] = description
      })
    }

    $scope.editInfotext = function (field) {
      if ($scope.userInfo.isSuperAdmin) {
        var modalScope = $scope.$new()
        modalScope.field = field
        modalScope.description = $scope.fieldDescriptions[field]
        if (modalScope.description == undefined) {
          modalScope.description = ''
        }
        $modal({ template: editInfotextTemplate, show: true, scope: modalScope })
      }
    }
    $scope.onPersonsChange = function (persons) {
      $scope.persons = persons
    }

    $scope.digitalSignatureWrapper = function (callback) {
      return function () {
        $scope.digitalSignature(callback)
      }
    }

    $scope.digitalSignature = function (callback) {
      console.log($scope.cvrs)
      if ($scope.modelVars.usePenneo) {
        const modalScope = $scope.$new()
        modalScope.callback = callback

        if ($scope.cvrs !== undefined && $scope.cvrs.length > 0) {
          $modal({
            template:
                '<div class="modal" tabindex="-1" role="dialog" ptb-modal aftalenr="aftalenr" application-id="id" engine="engine" when-done="callback" persons="persons" on-persons-change="onPersonsChange" $hide="$hide()"/>',
            show: true,
            scope: modalScope,
          })
        } else {
          const person1 = {
            name: $scope.modelVars.navn,
            cprCvr: $scope.modelVars.cpr,
            mail: $scope.modelVars.mail
          }

          const person2 = {
            name: $scope.modelVars.navn2,
            cprCvr: $scope.modelVars.cpr2,
            mail: $scope.modelVars.mail2
          }

          $scope.persons = $scope.modelVars.meddebitor ? {[person1.cprCvr]: [person1], [person2.cprCvr]: [person2]} : {[person1.cprCvr]: [person1]}

          console.log($scope.persons)

          $modal({
            template:
                '<div class="modal" tabindex="-1" role="dialog" person-digital-signature-modal engine="engine" when-done="callback" persons="persons" on-persons-change="onPersonsChange" $hide="$hide()"/>',
            show: true,
            scope: modalScope,
          })
        }
      } else {
        callback()
      }
    }

    $scope.acceptPopup = function () {
      if (
        $scope.engine.eval('hovedstol') >= $scope.userInfo.hovedstolBestyrelseGraense
      ) {
        var modalScope = $scope.$new()
        modalScope.renderAmount = $scope.renderAmount
        modalScope.acceptPopupFinalize = $scope.acceptPopupFinalize
        modalScope.hovedstol = $scope.engine.eval('hovedstol')
        modalScope.hovedstolGraense = $scope.engine.eval('hovedstolBestyrelseGraense')
        modalScope.samletEngagementGraense = $scope.engine.eval('samletEngagementBestyrelseGraense')

        $modal({ template: bestyrelsesgodkendelseTemplate, show: true, scope: modalScope })
      } else {
        $scope.acceptPopupFinalize()
      }
    }

    $scope.transferToKrone = function() {
      if ($scope.currentRow.kroneId) {
        const modalScope = $scope.$new(true)
        modalScope.kroneContractNo = $scope.currentRow.kroneContractNo
        modalScope.kroneId = $scope.currentRow.kroneId
        modalScope.transferToKroneApiCall = $scope.transferToKroneApiCall
        $modal({
          template: kroneTransferConfirmTemplate,
          show: true,
          scope: modalScope
        })
      } else {
        $scope.transferToKroneApiCall()
      }
    }

    $scope.transferToKroneApiCall = function () {
      $scope.kroneWorking = true
      $http
          .post(`/api/${$interop.module}/krone/export/` + $scope.id)
          .error(function (data, status, headers, config) {
            $scope.kroneWorking = false;

            if (data.error?.requestId) {
              return
            }

            var modalScope = $scope.$new(true)
            modalScope.message = data.message
            modalScope.matchError = data.matchError
            modalScope.missingError = data.missingError
            modalScope.negativeError = data.negativeError
            modalScope.objectKeys = $scope.objectKeys
            $modal({
              template: kroneErrorTemplate,
              show: true,
              scope: modalScope,
            })
          })
          .success(function (data, status, headers, config) {
            $scope.kroneWorking = false;
            $scope.kroneSuccess = true;
            $scope.currentRow.kroneContractNo = data.contractNo
            $scope.currentRow.kroneId = data.id
            $scope.setGlobalAlert('Sagen blev sendt til Krone')
          })
    }

    $scope.acceptPopupFinalize = function () {
      $scope.$broadcast('disposableIncome.save')
      if ($scope.modelVars.begrundelseAfvisning.trim() !== '') {
        $modal({ template: godkendafvisningfejlTemplate, show: true })
      } else {
        var modalScope = $scope.$new()
        modalScope.self = modalScope

        modalScope.penneoCCName = $scope.userLookup[modelVars.userId]?.user?.name ?? ''
        modalScope.penneoCCMail = $scope.userLookup[modelVars.userId]?.user?.mail ?? ''
        modalScope.validateEmail = $scope.validateEmail

        modalScope.dmrSynSenestDato = modelVars.dmrSynSenestDato
        modalScope.synAdvarsel = engine.eval('synAdvarsel')
        modalScope.renderDate = dateToDanish
        modalScope.acceptDisabled = false
        modalScope.docsIncludeDeliveryApproval = $scope.userLookup[$scope.modelVars.userId].company.leverancegodkendelseNemId ?? true
        modalScope.docsIncludeInvoice = true
        modalScope.externalSideEffects = true

        $modal({ template: acceptCommentTemplate, show: true, scope: modalScope })
      }
    }
    $scope.acceptWrapper = function (
      sendmail,
      comment,
      updateApprovalDate,
      forceNewAftalenr,
      selvanmelder,
      penneoCCName,
      penneoCCMail,
      docsIncludeDeliveryApproval,
      docsIncludeInvoice,
      externalSideEffects,
      callback,
    ) {
      console.log('penneoCCName, penneoCCMail in accept wrapper: ', penneoCCName, penneoCCMail)
      return function () {
        $scope.accept(
          sendmail,
          comment,
          updateApprovalDate,
          forceNewAftalenr,
          penneoCCName,
          penneoCCMail,
          docsIncludeDeliveryApproval,
          docsIncludeInvoice,
          externalSideEffects,
          callback,
        )
      }
    }

    $scope.accept = function (
      sendmail,
      comment,
      updateApprovalDate,
      forceNewAftalenr,
      penneoCCName,
      penneoCCMail,
      docsIncludeDeliveryApproval,
      docsIncludeInvoice,
      externalSideEffects,
      callback,
    ) {
      $scope.saving = true
      if (callback) {
        callback()
      }

      var sm = sendmail ? 1 : 0
      var ua = updateApprovalDate ? 1 : 0
      var fa = forceNewAftalenr ? 1 : 0
      $scope.modelVars.begrundelseAfvisning = ''

      $http
        .post(`/api/application/accept/${param.id}/${sm}/${ua}/${fa}`, {
          comment: comment || '',
          persons: $scope.persons,
          noPenneoCprValidation: $scope.noPenneoCprValidation,
          penneoCCName: penneoCCName,
          penneoCCMail: penneoCCMail,
          docsIncludeDeliveryApproval,
          docsIncludeInvoice,
          debtorNos: {},
          externalSideEffects
        })
        .success(function (res) {
          $scope.$broadcast('creditRating.save', { alert: false, setReviewer: true })
          $timeout(function () {
            $scope.saving = false
          })
          $scope.setGlobalAlert('Ansøgningen blev godkendt!')
          $scope.setKeepAlert(false)
          //$scope.transferToCerno(); Klares nu serverside i finalizeApproval!!
          $http.post('/api/application/get', param).success(loadForm).error(getFail)
        })
        .error(function (err) {
          $timeout(function () {
            $scope.saving = false
            $scope.setGlobalAlert('FEJL: Der skete en fejl ved accept')
          })
        })
    }

    $scope.rejectWithReason = function () {
      var modalScope = $scope.$new()
      modalScope.rejectWithComment = $scope.rejectWithComment
      modalScope.begrundelseAfvisning = $scope.modelVars.begrundelseAfvisning
      $modal({ template: rejectCommentTemplate, show: true, scope: modalScope })
    }

    $scope.handleStringDate = function (d) {
      if (typeof d == 'string') {
        var darr = d.split('-')
        if (darr.length == 3) {
          return new Date(parseInt(darr[0], 10), parseInt(darr[1], 10) - 1, parseInt(darr[2], 10))
        }
      }
      return d
    }
    $scope.getKnaek1Dato = function () {
      return $scope.handleStringDate($scope.engine.eval('knaek1dato')).getTime()
    }
    $scope.getKnaek2Dato = function () {
      return $scope.handleStringDate($scope.engine.eval('knaek2dato')).getTime()
    }
    $scope.forceacceptWrapper = function (isSplit, aftalenr, aftalenr2, sendmail, updateApprovalDate) {
      return function () {
        $scope.forceaccept(isSplit, aftalenr, aftalenr2, sendmail, updateApprovalDate)
      }
    }

    $scope.forceaccept = function (isSplit, aftalenr, aftalenr2, sendmail, updateApprovalDate) {
      var xs = [aftalenr]
      if (aftalenr2 > 0 && isSplit) {
        xs.push(aftalenr2)
      }
      xs.push(sendmail ? 1 : 0)
      xs.push(updateApprovalDate ? 1 : 0)
      var method = aftalenr2 > 0 && isSplit ? 'forceaccept2' : 'forceaccept1'
      $http.post('/api/application/' + method + '/' + param.id + '/' + xs.join('/')).success(function (res) {
        $scope.setGlobalAlert('Ansøgningen blev godkendt uden NAV-overførsel!')
        $scope.setKeepAlert(false)
        $http.post('/api/application/get', param).success(loadForm).error(getFail)
      })
    }
    $scope.setReview = function (status, userId) {
      var requestUrl = '/api/application/setReview/' + param.id + '/' + (status ? '1' : '0')
      if (typeof userId !== 'undefined') {
        requestUrl += '?userId=' + userId
      }
      $http.post(requestUrl).success(function (res) {
        $scope.setGlobalAlert('Ansøgningens behandlingsstatus blev ændret!')
        $scope.setKeepAlert(false)
        $http.post('/api/application/get', param).success(loadForm).error(getFail)
      })
    }
    $scope.reject = function (comment) {
      $http.post('/api/application/reject/' + param.id, { begrundelseAfvisning: comment }).success(function (res) {
        $scope.setGlobalAlert('Ansøgningen blev afvist!')
        $scope.setKeepAlert(false)
        $http.post('/api/application/get', param).success(loadForm).error(getFail)
      })
    }
    $scope.rejectWithComment = $scope.reject
    $scope.unapprove = function () {
      $http.post('/api/application/unapprove/' + param.id).success(function (res) {
        $scope.setGlobalAlert('Ansøgningens godkendelsesstatus blev fjernet!')
        $scope.setKeepAlert(false)
        $http.post('/api/application/get', param).success(loadForm).error(getFail)
      })
    }
    $scope.archive = function () {
      $http.post('/api/application/archive/' + param.id).success(function (res) {
        $scope.setGlobalAlert('Ansøgningen blev arkiveret!')
        $scope.setKeepAlert(false)
        $http.post('/api/application/get', param).success(loadForm).error(getFail)
      })
    }
    $scope.unarchive = function () {
      $http.post('/api/application/unarchive/' + param.id).success(function (res) {
        $scope.setGlobalAlert('Ansøgningens blev gendannet fra arkivet!')
        $scope.setKeepAlert(false)
        $http.post('/api/application/get', param).success(loadForm).error(getFail)
      })
    }
    $scope.saving = false
    $scope.saveButton = function (status, saveAsNew) {
      if ($scope.formIsLoaded) {
        var wait = false
        if ($scope.modelVars.status !== status) {
          $scope.modelVars.status = status
          wait = true
        }

        var f = function () {
          if (!$scope.form.$invalid) {
            if (status == 1) {
              $modal({ template: warningSendTemplate, show: true, scope: $scope })
            } else {
              $scope.save(saveAsNew) // save without modal
            }
          }
        }

        if (wait) {
          setTimeout(f, 100)
        } else {
          f()
        }
      }
    }

    var formatValue = function (varDef, val) {
      if (val === undefined) return undefined
      if (varDef.datatype == 'amount') {
        val = $scope.renderStaticAmount(val)
      } else if (varDef.datatype == 'percent') {
        val = $scope.renderStaticPct(val)
      } else if (varDef.datatype == 'percent3') {
        val = $scope.renderStaticPct3(val)
      } else if (varDef.datatype == 'count') {
        val = $scope.renderAmount(val)
      } else if (varDef.datatype == 'decimal') {
        val = $scope.renderDecimal(val, 2)
      } else if (varDef.datatype == 'singledecimal') {
        val = $scope.renderDecimal(val, 1)
      } else if (varDef.datatype === 'date') {
        val = $scope.renderDate(val)
      }
      return val
    }

    $scope.getMin = function (varName) {
      var varDef = $scope.variableDefs[varName]
      if (varDef.minExpr !== undefined) {
        return formatValue(varDef, $scope.engine.eval(varName + '.min'))
      }
    }
    $scope.getMax = function (varName) {
      var varDef = $scope.variableDefs[varName]
      if (varDef.maxExpr !== undefined) {
        return formatValue(varDef, $scope.engine.eval(varName + '.max'))
      }
    }

    $scope.save = function (saveAsNew) {
      $scope.saving = true

      justSaved = true
      var temp = {}
      for (var v in modelVars) {
        var d = modelVars[v]
        temp[v] = d instanceof Date ? dateToYMD(d) : d
      }

      var p = saveAsNew ? {} : param

      $http
        .post('/api/application/save', {
          param: p,
          modelVars: temp,
          uploaddir: $scope.uploaddir,
        })
        .success(function (res) {
          $scope.$broadcast('formCtrl.save.success')

          /*$scope.saveMessage = "Beregningen blev gemt";
          setTimeout(function() {
            $scope.saveMessage = "";
          },2000)*/
          if (saveAsNew) {
            $scope.setGlobalAlert('Beregningen blev gemt som kopi!')
          } else if (p.id === undefined) {
            $scope.setGlobalAlert('Beregningen blev gemt!')
          } else {
            $scope.setGlobalAlert('Beregningen blev opdateret!')
            $scope.setKeepAlert(false)
          }

          var subscope = $rootScope.$new()
          if (p.id === undefined) {
            $scope.form.$setPristine()

            if (res.message !== null) {
              subscope.close = function ($hide) {
                $location.url('/application/' + res.id)
                $hide()
              }
              subscope.message = res.message
              $modal({ template: applicationExternalLiabilitiesTemplate, show: true, scope: subscope })
            } else {
              $location.url('/application/' + res.id)
            }
          } else {
            $scope.$broadcast('creditRating.save', { alert: false, setReviewer: true })
            if (res.message != null) {
              subscope.close = function ($hide) {
                $hide()
              }
              subscope.message = res.message
              $modal({ template: applicationExternalLiabilitiesTemplate, show: true, scope: subscope })
            }
            // hent ned på ny
            $http.post('/api/application/get', param).success(loadForm).error(getFail)
          }
        })
        .error(function () {
          /*$scope.errorMessage = "Beregningen kunne ikke gemmes";
          setTimeout(function() {
            $scope.errorMessage= "";
          },2000)*/
        })
    }
  },
]
