import { assignScope, makeDirective } from '@/shared/util/directiveHelpers'
import importModalTemplate from '@/shared/index/importModal.html'
import errorTemplate from '@/shared/templates/modals/error.html'
import { IScope } from 'angular'

interface NormalError {
  message: string
}

interface MatchError {
  message: string
  matchError: { [k: string]: { expected: string; obtained: string } }
  missingError: string[]
  negativeError: string[]
  computedValues: any
  agreementEnvelope: any
}

interface StartTask {
  $discriminator: 'StartTask'
  filename: string
  task: string
  steps: number
}

interface UpdateTaskSteps {
  $discriminator: 'UpdateTaskSteps'
  filename: string
  task: string
  steps: number
}

interface CompletedTask {
  $discriminator: 'CompletedTask'
  filename: string
  task: string
  step: number
  data: any
}

interface FailedTask {
  $discriminator: 'FailedTask'
  filename: string
  task: string
  step: number
  error: any
}

interface Pong {
  $discriminator: 'Pong'
}

type S2CMessage = StartTask | UpdateTaskSteps | CompletedTask | FailedTask | Pong

type State = 'loading' | 'success' | 'error'

interface ProgressEntry {
  desc: string
  completed: number
  steps: number
}

export const importModal = makeDirective({
  inject: {
    $interop: '$interop',
    $timeout: '$timeout',
    $rootScope: '$rootScope',
    $modal: '$modal',
    $http: '$http',
  },
  template: importModalTemplate,
  scope: {
    $hide: {
      binding: 'function',
      type: Function,
    },
  },
  assignScope: assignScope<{
    activePanels: number[]
    entriesArr: string[]
    varDefs: { [k: string]: computationEngine.VariableDef }
    entries: {
      [filename: string]: {
        state: State
        processingTasks: string[]
        tasks: {
          [id: string]: {
            steps: number
            completed: number
          }
        }
        completeDatas: { task: string; data: any }[]
        maxProgress: number
        error?: NormalError | MatchError
      }
    }
    translatedProgressEntries: { [filename: string]: ProgressEntry[] }
    detailedTaskInfo: boolean
    objectKeys: typeof Object.keys
    translateState(state: State): string
    translateProgress(taskId: string): string
    translateProgressEntries(filename: string): any[]
    progressForFile(filename: string): number
    fillInspectLocalStorage(filename: string): void
    uploadFiles(files: FileList): void
  }>(),
  linkAssign({ $interop, $timeout, $rootScope, $modal, $http }, { scope }) {
    return {
      activePanels: [],
      entriesArr: [],
      entries: {},
      varDefs: {},
      translatedProgressEntries: {},
      detailedTaskInfo: false,
      objectKeys: Object.keys,
      translateState(state) {
        switch (state) {
          //case 'uploading':
          //  return 'Overfører'
          //case 'parsing':
          case 'loading':
            return 'Indlæser'
          //case 'creating':
          //  return 'Beregner'
          //case 'saving':
          //  return 'Gemmer'
          case 'success':
            return 'Færdig'
          case 'error':
            return 'Fejl'
          default:
            return state
        }
      },
      translateProgress(taskId): string {
        switch (taskId) {
          case 'import':
          case 'import.parse':
            return 'Læser fil'
          case 'import.create':
          case 'import.create.createApplicationFromEnvelope':
          case 'import.create.createApplicationFromEnvelope.gettingDbVals':
            return 'Indlæser konfiguration'
          case 'import.create.createApplicationFromEnvelope.calculatingApplication':
            return 'Kontrollerer fil'
          case 'import.create.createApplicationFromEnvelope.calculatingApplication.finalize':
            return 'Opretter sag'
          case 'import.create.createApplicationFromEnvelope.calculatingApplication.finalize.getBaseApplication':
            return 'Indlæser standardparametre'
          case 'import.create.createApplicationFromEnvelope.calculatingApplication.finalize.compute':
          case 'import.create.createApplicationFromEnvelope.calculatingApplication.finalize.compute.transform':
          case 'import.create.createApplicationFromEnvelope.calculatingApplication.finalize.compute.initialCompute':
          case 'import.create.createApplicationFromEnvelope.calculatingApplication.finalize.compute.basisInterestOverrrideCompute':
          case 'import.create.createApplicationFromEnvelope.calculatingApplication.finalize.compute.validate':
          case 'import.create.createApplicationFromEnvelope.calculatingApplication.finalize.computeExplicit':
            return 'Beregner sag'
          case 'import.save':
          case 'import.save.saveApplication':
          case 'import.save.saveApplication.setup':
          case 'import.save.saveApplication.save':
            return 'Gemmer sag'
          case 'import.save.saveApplication.approve':
            return 'Sætter status'
          default:
            return taskId
        }
      },
      translateProgressEntries(filename) {
        const entry = scope.entries[filename]
        const translatedTasks = Object.fromEntries(entry.processingTasks.map((t) => [t, scope.translateProgress(t)]))

        const outputArr: ProgressEntry[] = []
        const outputs: { [translation: string]: ProgressEntry } = {}

        for (const taskId of entry.processingTasks) {
          const task = entry.tasks[taskId]
          const translation = translatedTasks[taskId]

          if (!outputs[translation]) {
            outputs[translation] = {
              desc: translation,
              completed: 0,
              steps: 0,
            }
            outputArr.push(outputs[translation])
          }

          const output = outputs[translation]
          output.completed += task.completed
          output.steps += task.steps
        }

        return outputArr
      },
      progressForFile(filename: string) {
        const entry = scope.entries[filename]
        let progress = 0
        let contribution = 1

        if (entry.processingTasks.length === 0) {
          return 100
        }

        for (const taskId of entry.processingTasks) {
          const task = entry.tasks[taskId]
          progress += (task.completed / task.steps) * contribution
          contribution = contribution / task.steps
        }

        progress *= 100
        entry.maxProgress = Math.max(entry.maxProgress, progress)
        return entry.maxProgress
      },
      fillInspectLocalStorage(filename) {
        const entry = scope.entries[filename]
        if (!entry.error || !('matchError' in entry.error)) {
          return
        }

        localStorage.setItem('modelVarsPreload', JSON.stringify(entry.error.computedValues))
      },
      async deleteApplication(filename: string) {
        const entry = scope.entries[filename]
        const id = entry.completeDatas[0].data.id
        await $http.post(`/api/v1/application/delete/${id}`, {})
        delete scope.entries[filename]
        scope.entriesArr.splice(scope.entriesArr.indexOf(filename), 1)
      },
      async uploadFiles(files) {
        const loc = window.location
        const ws = new WebSocket(
          `${loc.protocol === 'https:' ? 'wss' : 'ws'}://${loc.host}/api/${$interop.module}/legacy/upload/excel`,
        )
        const processing: string[] = []
        let expectingPong = false

        const heartbeatInterval = setInterval(() => {
          ws.send(JSON.stringify({ $discriminator: 'Ping' }))
          if (!expectingPong) {
            expectingPong = true
          } else {
            error('Did not receive Pong', { code: 1003, reason: 'Did not expect pong' })
          }
        }, 5000)

        function error(message: string, close?: { code: number; reason: string }): void {
          if (close) {
            clearInterval(heartbeatInterval)
            ws.close(close.code, close.reason)
          }

          const modalScope = scope.$new(true) as IScope & {
            content: string
          }
          modalScope.content = message
          $modal({ template: errorTemplate, show: true, scope: modalScope })
        }

        ws.addEventListener('close', (ev) => {
          console.log(ev)
          clearInterval(heartbeatInterval)
          if (ev.reason) {
            error(ev.reason)
          } else if (ev.code === 1009) {
            error('File too big')
          }
        })

        ws.addEventListener('message', (e) => {
          $timeout(() => {
            const rawData = e.data as string
            const data = JSON.parse(rawData) as S2CMessage
            console.log(data)
            if (data.$discriminator === 'Pong') {
              if (expectingPong) {
                expectingPong = false
              } else {
                error('Did not expect Pong', { code: 1003, reason: 'Did not expect pong' })
              }

              return
            }

            const entry = scope.entries[data.filename]

            switch (data.$discriminator) {
              case 'StartTask':
                entry.processingTasks.push(data.task)
                entry.tasks[data.task] = {
                  steps: data.steps,
                  completed: 0,
                }
                break

              case 'UpdateTaskSteps':
                if (entry.tasks[data.task]) {
                  entry.tasks[data.task].steps = data.steps
                }
                break

              case 'CompletedTask':
                if (entry.tasks[data.task]) {
                  const taskObj = entry.tasks[data.task]
                  taskObj.completed = data.step + 1
                  if (taskObj.completed === taskObj.steps) {
                    entry.processingTasks.splice(entry.processingTasks.indexOf(data.task), 1)
                  }

                  if (data.data) {
                    entry.completeDatas.push({ task: data.task, data: data.data })
                  }

                  if (entry.processingTasks.length === 0) {
                    entry.state = 'success'
                    processing.splice(processing.indexOf(data.filename), 1)
                  }
                }
                break

              case 'FailedTask':
                entry.state = 'error'
                entry.processingTasks.filter((t) => t === data.task)
                processing.splice(processing.indexOf(data.filename), 1)
                entry.error = data.error
                break
            }
            scope.translatedProgressEntries[data.filename] = scope.translateProgressEntries(data.filename)

            if (processing.length === 0) {
              clearInterval(heartbeatInterval)
              ws.close()
            }
          })
        })

        while (ws.readyState === WebSocket.CONNECTING) {
          await $timeout(() => {}, 100)
        }

        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          ws.send(JSON.stringify({ $discriminator: 'UploadingFile', filename: file.name, length: file.size }))
          if (!scope.entriesArr.includes(file.name)) {
            scope.entriesArr.push(file.name)
          }
          processing.push(file.name)
          scope.entries[file.name] = {
            state: 'loading',
            processingTasks: [],
            tasks: {},
            completeDatas: [],
            maxProgress: 0,
          }

          ws.send(file)
        }
      },
    }
  },
})
