import { assignScope, makeDirective, type ScopeType } from '@/shared/util/directiveHelpers'
import indexActionsTemplate from './indexActions.html'
import errorTemplate from '@/shared/templates/modals/error.html'
import warningWithCallback from '@/shared/templates/modals/warningWithCallback.html'
import type { IScope } from 'angular'
import type { CarleasingInterop } from '@/shared/carleasingInterop'
import type { UserInfo } from '@/shared/util/injectTypes'

type ImportResult = { id?: number; error?: string }[]

export const indexActions = makeDirective({
  inject: {
    $state: '$state',
    $modal: '$modal',
    $upload: '$upload',
    $rootScope: '$rootScope',
    $interop: '$interop',
  },
  template: indexActionsTemplate,
  scope: {
    userInfo: {
      type: Object as unknown as ScopeType<UserInfo>,
      binding: 'twoWay',
    },
  },
  assignScope: assignScope<{
    $interop: CarleasingInterop
    uploading: boolean
    uploadAsg(file: FileList): void
    uploadNordania(file: FileList, regTax: boolean): void
    uploadLegacyExcel(file: FileList): void
    showCreateLead(): void
  }>(),
  linkAssign({ $state, $modal, $upload, $rootScope, $interop }, { scope }) {
    return {
      $interop,
      uploading: false,
      uploadAsg(file) {
        var uploadUrl = '/api/firstlease/asg/upload/carTax'
        scope.uploading = true

        $upload
          .upload<ImportResult>({
            url: uploadUrl,
            file: file,
          })
          .error(() => $modal({ template: errorTemplate, show: true, content: 'Fejl ved indlæsning af ASG-sag' }))
          .success((data, status) => {
            if (status != 200 || data.some((d) => d.error)) {
              $modal({ template: errorTemplate, show: true, content: 'Fejl ved indlæsning af ASG-sag' })
            } else {
              $rootScope.setGlobalAlert('ASG-sag blev indlæst')
              if (data[0] && data[0].id) {
                $state.go('root.application.edit', { id: data[0].id })
              }
            }
          })
      },

      uploadNordania(file) {
        var uploadUrl = '/api/firstlease/nordania/upload/sale'
        scope.uploading = true

        function renderStaticPct(value: number) {
          return (value * 100).toFixed(4).replace('.', ',') + ' %'
        }

        $upload
          .upload<
            ImportResult & { isCurrentPrtRate: boolean; prtRate: { interest: number; year: number; half: number } }[]
          >({
            url: uploadUrl,
            file: file,
          })
          .error(() => $modal({ template: errorTemplate, show: true, content: 'Fejl ved indlæsning af Nordania-sag' }))
          .success((data, status) => {
            if (status != 200 || data.some((d) => d.error)) {
              $modal({ template: errorTemplate, show: true, content: 'Fejl ved indlæsning af Nordania-sag' })
            } else {
              if (!data[0].isCurrentPrtRate) {
                var subscope = scope.$new(true) as IScope & { content: string; callback(): void }
                subscope.content =
                  'Skats rentesats for restafgift er en anden end den aktive pr. dags dato. ' +
                  `Den indlæste sag er beregnet ud fra en rente på ${renderStaticPct(data[0].prtRate.interest)} (${
                    data[0].prtRate.year
                  }, ${data[0].prtRate.half}. halvår). ` +
                  'Forvent derfor en anden ydelse.'
                subscope.callback = function () {
                  $rootScope.setGlobalAlert('Nordania-sag blev indlæst')
                  if (data[0] && data[0].id) {
                    $state.go('root.application.edit', { id: data[0].id })
                    // reloadTable()
                  }
                }

                $modal({ template: warningWithCallback, show: true, scope: subscope })
              } else {
                $rootScope.setGlobalAlert('Nordania-sag blev indlæst')
                if (data[0] && data[0].id) {
                  $state.go('root.application.edit', { id: data[0].id })
                  // reloadTable()
                }
              }
            }
          })
      },

      showUploadLegacyExcel() {
        $modal({
          template: `<div class="modal" tabindex="-1" role="dialog" import-modal $hide="$hide()"></div>`,
          show: true,
        })
      },

      showCreateLead() {
        $modal({ template: '<div class="modal" tabindex="-1" role="dialog" create-lead $hide="$hide()"/>', show: true })
      },
    }
  },
})
