import type { CarleasingInterop } from '@/shared/carleasingInterop'
import { ComputationEngine2 } from '@/computationengine/ComputationEngine2'
import type { IHttpService } from 'angular'
import { UserInfo } from '@/shared/util/injectTypes'

export const coreInterop: Omit<CarleasingInterop, 'module' | 'features' | 'prettyModuleName' | 'controlPanelStates'> = {
  specific: {
    applicationMailOffer: {
      pdflink: 'offer'
    },
    navTransferModal: {
      title: 'Vælg modtagersystem'
    },
    commonConfig: { splitForsikringLegend: 'Split-forsikring' },
    creditlinkSummary: {
      headerName: 'Creditlink'
    },
    asgBox: {
      afgiftBeregnetAfAsg: 4
    },
    externalPartnerChoice: {
      options: [],
      defaultChoice: ''
    },
    formCompanyCtrl: {
      async getModelVars($http: IHttpService): Promise<any> {
        const res = await $http.get<any>('/api/company/empty')
        const obj = res.data
        obj.hasAccess = true
        obj.digitalSignatureToProd = true
        obj.defaultAnviser2 = 1
        return obj
      }
    },
    formCompanyGroupCtrl: {
      getCommonConfig: true,
      defaultProductId: 1
    },
    formInsuranceCostsCtrl: {
      kind: [
        { value: 0, option: 'P (Personbil, indregistreret, uden glas, el)' },
        { value: 1, option: 'V (Varevogn, indregistreret, uden glas, el)' },
        { value: 2, option: 'PS (Personbil, ej indregistreret, uden glas, el)' },
        { value: 3, option: 'VS (Varevogn, ej indregistreret, uden glas, el)' },
        { value: 4, option: 'PG (Personbil, indregistreret, med glas, el)' },
        { value: 5, option: 'VG (Varevogn, indregistreret, med glas, el)' },
        { value: 6, option: 'PSG (Personbil, ej indregistreret, med glas, el)' },
        { value: 7, option: 'VSG (Varevogn, ej indregistreret, med glas, el)' },
        { value: 8, option: 'PF (Personbil, indregistreret, uden glas, fossil)' },
        { value: 9, option: 'VF (Varevogn, indregistreret, uden glas, fossil)' },
        { value: 10, option: 'PSF (Personbil, ej indregistreret, uden glas, fossil)' },
        { value: 11, option: 'VSF (Varevogn, ej indregistreret, uden glas, fossil)' },
        { value: 12, option: 'PGF (Personbil, indregistreret, med glas, fossil)' },
        { value: 13, option: 'VGF (Varevogn, indregistreret, med glas, fossil)' },
        { value: 14, option: 'PSGF (Personbil, ej indregistreret, med glas, fossil)' },
        { value: 15, option: 'VSGF (Varevogn, ej indregistreret, med glas, fossil)' }
      ]
    },
    formSelvanmelderCtrl: {
      options: {
        selvanmelder: [
          {
            value: false,
            option: 'Krone Kapital'
          },
          {
            value: true,
            option: 'Krone Kapital'
          }
        ]
      }
    },
    formUserCtrl: {
      dmrEmailParts: [
        {
          name: 'Forskel i registreringsnummer',
          id: 'registreringsnummerDiff'
        },
        {
          name: 'Indregistrerede biler UDEN forsikring',
          id: 'aktivUdenForsikring'
        },
        {
          name: 'Biler uden indregistrering',
          id: 'udenIndregistrering'
        },
        {
          name: 'Biler med indregistrering, under etablering',
          id: 'medIndregistrering'
        },
        {
          name: 'Biler uden stelnummer i NAV',
          id: 'udenStelnummer'
        },
        {
          name: 'Biler med fællespolice men anden forsikring',
          id: 'fællespoliceAndenForsikring'
        },
        {
          name: 'Automatiske ændringer foretaget i NAV',
          id: 'navChanges'
        }
      ]
    },
    headerCtrl: {
      userLinks: false,
      alternateAccounts: true
    },
    registerRkiCtrl: {
      makeCalls: false
    },
    dkkapAnnualReports: {
      doCalls: false
    },
    dkkapCreditReport: {
      doValidation: true,
      makeCalls: false
    },
    ngAutofield: {
      ngPercentageValueStr: '4',
      makeDescription(_, varName) {
        return '{{engine.eval(\'' + varName + '.friendly\')}}'
      }
    },
    ngPercentage: {
      toFixedArgs(attrs) {
        return attrs.ngPercentage
      }
    },
    index: {
      showAftalenr: true,
      showId: false,
      showStatus: false,
      showUdloebsdato: true,

      //      <th ng-if="$interop.specific.index.showAftalenr" style="width: 3%">
      //         <a ng-class="{'dropup' : !tableState.reverse}" ng-click="setOrder('aftalenr')">
      //           Aftalenr. <span ng-class="{'caret' : tableState.orderBy == 'aftalenr'}"></span>
      //         </a>
      //       </th>
      //       <th ng-if="$interop.specific.index.showId" style="width: 5%">
      //         <a ng-class="{'dropup' : !tableState.reverse}" ng-click="setOrder('id')">
      //           ID <span ng-class="{'caret' : tableState.orderBy == 'id'}"></span>
      //         </a>
      //       </th>
      //       <th style="width: 5%">
      //         <a ng-class="{'dropup' : !tableState.reverse}" ng-click="setOrder('product')">
      //           Produkt <span ng-class="{'caret' : tableState.orderBy == 'product'}"></span>
      //         </a>
      //       </th>
      //       <th ng-show="userInfo.isSuperAdmin" style="width: 10%">
      //         <a ng-class="{'dropup' : !tableState.reverse}" ng-click="setOrder('company')">
      //           Forhandler <span ng-class="{'caret' : tableState.orderBy == 'company'}"></span>
      //         </a>
      //       </th>
      //       <th ng-show="userInfo.visKollegersAnsoegninger" style="width: 10%;">
      //         <a ng-class="{'dropup' : !tableState.reverse}" ng-click="setOrder('user')">
      //           Sælger <span ng-class="{'caret' : tableState.orderBy == 'user'}"></span>
      //         </a>
      //       </th>
      //       <th style="width: 9%">
      //         <a ng-class="{'dropup' : !tableState.reverse}" ng-click="setOrder('maerkeModel')">
      //           Mærke/model <span ng-class="{'caret' : tableState.orderBy == 'maerkeModel'}"></span>
      //         </a>
      //       </th>
      //       <th style="width: 9%">
      //         <a ng-class="{'dropup' : !tableState.reverse}" ng-click="setOrder('navn')">
      //           Kunde <span ng-class="{'caret' : tableState.orderBy == 'navn'}"></span>
      //         </a>
      //       </th>
      //       <th style="width: 7%">
      //         <a ng-class="{'dropup' : !tableState.reverse}" ng-click="setOrder('lastModifiedRaw')">
      //           <span ng-if="userInfo.isSuperAdmin">Sidst ændret</span>
      //           <span ng-if="!userInfo.isSuperAdmin">Sidst ændret</span>
      //           <span ng-class="{'caret' : tableState.orderBy == 'lastModifiedRaw'}"></span>
      //         </a>
      //       </th>
      //       <th style="width: 7%" ng-if="$interop.specific.index.showUdloebsdato">
      //         <a ng-class="{'dropup' : !tableState.reverse}" ng-click="setOrder('udloebsdato')">
      //           Udløbsdato <span ng-class="{'caret' : tableState.orderBy == 'udloebsdato'}"></span>
      //         </a>
      //       </th>
      //       <th style="width: 3%" ng-if="$interop.module === 'firstlease'">Bevilling</th>
      //       <th style="width: 10%" ng-show="!userInfo.isSuperAdmin"><a>Under behandling af</a></th>
      //       <th style="width: 5%" ng-if="$interop.specific.index.showStatus">Status</th>
      //       <th style="width: 6%">Ref.</th>


      //       <td ng-if="$interop.specific.index.showAftalenr">
      //         {{a.aftalenr}}
      //         <span ng-if="a.externalPartner == 'Krone'"><img style="height: 16px" src="/assets/images/externalpartner/krone-icon.png" /> {{a.externalContractNo}}</span>
      //         <span ng-if="a.externalPartner == 'Santander'"><img style="height: 16px" src="/assets/images/externalpartner/santander-icon.png" /> {{a.externalContractNo}}</span>
      //       </td>
      //       <td ng-if="$interop.specific.index.showId">F{{a.id}} {{a.aftalenr}}</td>
      //       <td>{{a.product}}</td>
      //       <td ng-show="userInfo.isSuperAdmin">{{a.company}}</td>
      //       <td ng-show="userInfo.visKollegersAnsoegninger">
      //         {{a.user.length > 28 ? a.user.substring(0, 28) + "..." : a.user}}
      //       </td>
      //       <td>{{a.maerkeModel.length > 24 ? a.maerkeModel.substring(0,24) + "..." : a.maerkeModel}}</td>
      //       <td>{{a.navn.length > 24 ? a.navn.substring(0,24) + "..." : a.navn}}</td>
      //       <td>{{a.lastModified}}</td>
      //       <td ng-if="$interop.specific.index.showUdloebsdato">{{a.udloebsdato}}</td>
      //       <td ng-if="$interop.module === 'firstlease'">
      //         <span class="label" ng-class="creditAnalysisColor(a)" data-html="true" bs-tooltip="creditAnalysisTooltip(a)">
      //           {{creditAnalysisCount(a)}} / {{ creditAnalysisTotal(a) }}
      //         </span>
      //       </td>
      //       <td ng-show="!userInfo.isSuperAdmin">
      //         <a ng-if="a.status == 1" bs-tooltip="'Telefon: ' + a.reviewEmployeePhone">{{a.reviewEmployeeName}}</a>
      //       </td>
      //       <td ng-if="$interop.specific.index.showStatus">
      //         {{a.statusText}}
      //         <a
      //           ng-if="a.initials.length > 0"
      //           bs-dropdown="seeInitialsInfo(a.initialsEmployeeName, a.initialsEmployeeMail, a.initialsEmployeePhone)"
      //           data-template="@/shared/templates/angularstrap/alternativeDropdown.html"
      //           data-html="true"
      //           class="btn btn-default btn-xs"
      //         ><i class="fa fa-user"></i> {{a.initials}}
      //         </a>
      //       </td>
      //       <td ng-if="a.status <= 1">
      //         <span ng-if="a.useLoebetidWrite">{{a.loebetidWrite}} md.</span>
      //         <span ng-if="!a.useLoebetidWrite">{{a.ydelseWrite}} kr/md.</span>
      //       </td>

      columns: [
        {
          displayName: 'Aftalenr.',
          order: 'aftalenr',
          width: '3%',
          show(userInfo: UserInfo, self: CarleasingInterop): boolean {
            return self.specific.index.showAftalenr
          },
          template: `
          {{a.aftalenr}}
          <span ng-if="a.externalPartner == 'Krone'"><img style="height: 16px" src="/assets/images/externalpartner/krone-icon.png" /> {{a.externalContractNo}}</span>
          <span ng-if="a.externalPartner == 'Santander'"><img style="height: 16px" src="/assets/images/externalpartner/santander-icon.png" /> {{a.externalContractNo}}</span>`
        },
        {
          displayName: 'ID',
          order: 'id',
          width: '5%',
          show(userInfo: UserInfo, self: CarleasingInterop): boolean {
            return self.specific.index.showId
          },
          template: 'F{{a.id}} {{a.aftalenr}}'
        },
        {
          displayName: 'Produkt',
          order: 'product',
          width: '5%',
          show(userInfo: UserInfo): boolean {
            return userInfo.isSuperAdmin
          },
          template: '{{a.product}}'
        },
        {
          displayName: 'Forhandler',
          order: 'company',
          width: '10%',
          show(userInfo: UserInfo): boolean {
            return userInfo.isSuperAdmin
          },
          template: '{{a.company}}'
        },
        {
          displayName: 'Sælger',
          order: 'user',
          width: '10%',
          show(userInfo: UserInfo): boolean {
            return userInfo.visKollegersAnsoegninger
          },
          template: '{{a.user.length > 28 ? a.user.substring(0, 28) + "..." : a.user}}'
        },
        {
          displayName: 'Mærke/model',
          order: 'maerkeModel',
          width: '9%',
          show(): boolean {
            return true
          },
          template: '{{a.maerkeModel.length > 24 ? a.maerkeModel.substring(0,24) + "..." : a.maerkeModel}}'
        },
        {
          displayName: 'Kunde',
          order: 'navn',
          width: '9%',
          show(): boolean {
            return true
          },
          template: '{{a.navn.length > 24 ? a.navn.substring(0,24) + "..." : a.navn}}'
        },
        {
          displayName: 'Sidst ændret',
          order: 'lastModifiedRaw',
          width: '7%',
          show(): boolean {
            return true
          },
          template: '{{showDatetime(a.lastModifiedRaw)}}'
        },
        {
          displayName: 'Udløbsdato',
          order: 'udloebsdato',
          width: '7%',
          show(userInfo: UserInfo, self: CarleasingInterop): boolean {
            return self.specific.index.showUdloebsdato
          },
          template: '{{a.udloebsdato}}'
        },
        {
          displayName: 'Bevilling',
          width: '3%',
          show(userInfo: UserInfo, self: CarleasingInterop): boolean {
            return self.module === 'firstlease'
          },
          template: `
          <span class="label" ng-class="creditAnalysisColor(a)" data-html="true" bs-tooltip="creditAnalysisTooltip(a)">
            {{creditAnalysisCount(a)}} / {{ creditAnalysisTotal(a) }}
          </span>`
        },
        {
          displayName: 'Under behandling af',
          width: '10%',
          show(userInfo: UserInfo): boolean {
            return !userInfo.isSuperAdmin
          },
          template: '<a ng-if="a.status == 1" bs-tooltip="\'Telefon: \' + a.reviewEmployeePhone">{{a.reviewEmployeeName}}</a>'
        },
        {
          displayName: 'Status',
          width: '5%',
          show(userInfo: UserInfo, self: CarleasingInterop): boolean {
            return self.specific.index.showStatus
          },
          template: `
          {{a.statusText}}
          <a
            ng-if="a.initials.length > 0"
            bs-dropdown="seeInitialsInfo(a.initialsEmployeeName, a.initialsEmployeeMail, a.initialsEmployeePhone)"
            data-template="@/shared/templates/angularstrap/alternativeDropdown.html"
            data-html="true"
            class="btn btn-default btn-xs"
          ><i class="fa fa-user"></i> {{a.initials}}
          </a>`
        },
        {
          displayName: 'Ref.',
          width: '6%',
          show(): boolean {
            return true
          },
          template: `
            <div ng-if="a.status <= 1">>
              <span ng-if="a.useLoebetidWrite">{{a.loebetidWrite}} md.</span>
              <span ng-if="!a.useLoebetidWrite">{{a.ydelseWrite}} kr/md.</span>
            </div>
          `
        }
      ]
    },
    acceptComment: {
      showEnableExternalSideEffects: true,
      showDocsIncludeDeliveryApproval: false,
      showDocsIncludeInvoice: false,
      showPenneoCC: true
    }
  },
  terms: {
    downPayment: 'Førstegangsydelse',
    administrationFee: 'Administrationsgebyr'
  },
  creditlinkUrl: 'https://app.casehub.dk/admin/cartest/admin', //TODO: Change
  friendlyFromDef(def, engine) {
    const ret = (engine as computationEngine.ComputationEngine<any>).eval(
      (def as { friendlyExpr: string }).friendlyExpr
    ) as string | undefined

    if (ret === undefined) {
      const tempEngine = new ComputationEngine2()
      return tempEngine.eval((def as { friendlyExpr: string }).friendlyExpr) as string
    } else {
      return ret
    }
  },
  ComputationEngine: ComputationEngine2
}

export function makeCoreInterop(
  specifics: {
    module: CarleasingModule
    features: CarleasingFeature[]
    prettyModuleName: string
    controlPanelStates: ControlPanelState[]
  } & Partial<CarleasingInterop>
): CarleasingInterop {
  return {
    ...coreInterop,
    ...specifics
  }
}
